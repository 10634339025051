@include keyframes(rotate90) {
	from {
		@include transform(rotateZ(0deg));
	}
	to {
		@include transform(rotateZ(90deg));
	}
}

@include keyframes(rotate0) {
	from {
		@include transform(rotateZ(90deg));
	}
	to {
		@include transform(rotateZ(0deg));
	}
}

@include keyframes(gradientMove) {
	0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@include keyframes(triangleSplash) {
	0% {
		transform:translate(0, -150px);
	}
	100% {
		transform:translate(0, 0);
	}
}