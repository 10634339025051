$xlg: new-breakpoint(min-width 1400px);
$lg: new-breakpoint(min-width 1200px);
$md: new-breakpoint(min-width 992px);
$sm: new-breakpoint(min-width 768px);
$xs: new-breakpoint(min-width 480px);
$def: new-breakpoint(min-width 1px);

$h-def: new-breakpoint(min-height 1px);
$h-sm: new-breakpoint(min-height 980px);
$h-xsm: new-breakpoint(min-height 700px);

$max-width:em(1400) !global;