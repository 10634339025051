html,
body {
	height: 100%;
}

body {
	font-family: "aktiv-grotesk", Helvetica, sans-serif;
	-webkit-font-smoothing: antialiased;
	font-weight: 400;
	font-size: 16px;
	color: $navy;
	overflow-x: hidden;
	max-width: 100%;
	nav#mainMobile {
		padding: 20px 0;
		position: relative;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: 200;
		background-color: white;
		&.active {
			position: fixed;
			.links {
				background-color: $grayBlue;
				height: 100%;
				ul {
					li {
						a {
							color: white;
						}
					}
				}
			}
			.nav {
				padding-bottom: 20px;
			}
		}
		@include media($md) {
			display: none;
		}
		.left {
			@include span-columns(6);
		}
		.right {
			@include span-columns(6);
			img {
				max-width: 250px;
			}
		}
		span.menuBtn {
			color: $blue;
			font-size: 24px;
			padding-left: 40px;
			position: relative;
			cursor: pointer;
			font-weight: bold;
			@include media($md) {
				display: none;
			}
			&.active {
				&:before {
					content: "\E5CD";
				}
			}
			&:before {
				display: block;
				content: "menu";
				position: absolute;
				left: 0;
				top: -2px;
				font-family: "Material Icons";
				font-size: 34px;
			}
		}
		.links {
			display: none;
			padding: 25px 0 0 0;
			> ul {
				> li {
					> a {
						&:before {
							content: "\E5C5";
							font-family: "Material Icons";
							position: absolute;
							right: 0px;
							top: 0;
							color: white;
							font-size: 20px;
						}
					}
				}
			}
			ul {
				@include ulReset;
				li {
					margin: 15px 0;
					font-size: 21px;
					a {
						position: relative;
						color: $blue;
						text-transform: uppercase;
						padding: 0 20px;
					}
					ul {
						display: none;
						padding: 10px 0;
						margin: 10px 0;
						background-color: $lightBlue;
						li {
							font-size: 16px;
						}
					}
				}
			}
		}
		@include media($md) {
			display: none;
		}
	}
	.navContain {
		position: fixed;
		top: 65px;
		left: 0;
		right: 0;
		z-index: 200;
		background-color: white;
		display: none;
		@include media($md) {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			background-color: transparent;
			display: block;
		}
	}
	nav#main {
		display: none;
		@include media($md) {
			padding: 20px 0;
			display: block;
			z-index: 500;
			width: 100%;
			background-color: white;
			margin-top: 0;
		}
		> .c {
			@include clearfix;
		}
		/* div.nav {
			@include clearfix;
			@include media($md) {
				@include span-columns(9);
			}
			> ul {
				@include clearfix;
				@include media($md) {
					height: 70px;
				}
				li {
					&:first-child {
						margin-left: 0;
					}
				}
			}
			ul {
				@include ulReset;
				@include media($md) {}
				> li {
					position: relative;
					height: 100%;
					&:hover {
						ul {
							@include media($md) {
								display: block;
								width: 320px;
								background: white;
								position: absolute;
								left: 0;
								top: 35px;
								padding-top: 60px;
								z-index: 100;
								padding-bottom: 20px;
							}
						}
					}
				}
				li {
					font-weight: 700;
					font-size: 21px;
					margin: 30px 0;
					text-transform: uppercase;
					background-color: white;
					&.parent-here {
						&#nav-sub-2 {
							> a {
								color: $purple;
							}
						}
						&#nav-sub-3 {
							> a {
								color: $teal;
							}
						}
					}
					@include media($md) {
						float: left;
						margin: 0 20px;
					}
					a {
						color: $blue;
						text-decoration: none;
					}
					ul {
						display: none;
						li {
							margin: 15px 0;
							font-size: 18px;
							padding: 0 30px;
							a {
								color: $grayBlue;
							}
						}
					}
				}
			}
		} */
		div.logo {
			text-align: left;
			img {
				width: 80px;
				//height: 36px;
				max-width: 100%;
				margin-left: 30px;
			}
			a {
				i {
					font-size: 36px;
					position: relative;
					top: -10px;
				}
			}
		}
		div#search {
			display: flex;
			align-items: center;
			span.twitter-typeahead {
				width: 100%;
				text-align: left;
				.tt-menu {
					width: 100%;
					.tt-suggestion {
						background: white;
						width: 100%;
						padding: 5px 10px;
						cursor: pointer;
						&:hover {
							background-color: #fafafa;
						}
					}
				}
			}
			form {
				position: relative;
				.box {
					box-shadow: 2px 2px 14px 0px rgba(92, 107, 126, 0.3);
					width: 300px;
					height: 50px;
					input[type="submit"] {
						position: absolute;
						right: 0;
						top: 0;
						height: 50px;
						background-color: $teal;
						border: 0;
						width: 50px;
						border-radius: 0;
						padding: 0;
						font-family: "Material Icons";
						&:hover {
							@include katyButtonGradient;
						}
					}
					input[type="text"] {
						padding: 0px 60px 0 10px;
						height: 100%;
					}
				}
			}
		}
	}
	.navContain__cont {
		display: flex;
		justify-content: space-between;
		&::after {
			display: none !important;
		}
	}
	nav#subnav {
		@include katyGradient;
		//padding: 13px 0 0;
		display: none;
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 9999px;
			background-color: $grayBlue;
			position: absolute;
		}
		@include media($md) {
			display: block;
			height: 48px;
			&:before {
				display: none;
			}
		}
		.c {
			height: 100%;
			> ul {
				height: 100%;
				li {
					&:first-child {
						margin-left: 0;
					}
				}
				> li {
					height: 100%;
					font-size: 17px;
					text-transform: uppercase;
					margin: 0 25px;
					@include media($lg) {
						font-size: 21px;
						display: flex;
						align-items: center;
					}
					> a {
						position: relative;
						&:after {
							content: "\E5C5";
							font-size: 24px;
							display: block;
							position: absolute;
							right: -25px;
							top: 0px;
							font-family: "Material Icons";
							color: white;
						}
					}
					&.quick {
						float: right;
						font-size: 14px;
						> a {
							&:after {
								display: none;
							}
							//background-color: $grayBlue;
							color: white;
							display: inline-block;
							padding: 5px 20px;
							position: relative;
							height: 100%;
							display: flex;
							align-items: center;
						}
					}
					&.previous {
						float: right;
						a {
							font-size: 12px;
							color: white;
							position: relative;
							top: -4px;
							i {
								display: inline;
								position: relative;
								top: 5px;
								left: auto;
								font-size: 20px;
								right: auto;
								color: white;
								padding-right: 5px;
							}
							&:after {
								display: none;
							}
						}
					}
				}
			}
			img {
				&.searchIcon {
					position: absolute;
					right: 18px;
					top: -2px;
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
			}
			i {
				display: none;
				font-size: 36px;
				position: absolute;
				right: 16px;
				top: -5px;
				cursor: pointer;
				color: white;
			}
		}
		ul {
			@include ulReset;
			@include clearfix;
			li {
				font-weight: 700;
				margin: 15px 0; //position: relative;
				@include media($md) {
					font-size: 14px;
					float: left;
					margin: {
						top: 0px;
						left: 10px;
						right: 10px;
						bottom: 0;
					}
				}
				@include media($lg) {
					font-size: 16px;
					margin: {
						top: 0px;
						left: 20px;
						right: 20px;
						bottom: 0;
					}
				}
				a {
					color: white;
					text-decoration: none;
				}
				&:hover {
					> div {
						display: block;
					}
				}
				> div {
					height: 320px;
					margin-top: 48px;
					display: none;
					position: absolute;
					left: 0;
					top: 0;
					padding-top: 30px;
					width: 100%;
					a {
						color: $navy;
					}
					.left {
						ul {
							li {
								font-size: 18px;
								font-weight: bold;
								color: white;
								margin: 0;
								text-align: center;
								img {
									width: 63px;
									height: 63px;
									display: block;
									margin: 0 auto;
									margin-bottom: 20px;
								}
							}
						}
					}
					.right {
						ul {
							li {
								color: $navy;
								font-size: 18px;
								margin: 10px 0;
								position: relative;
								padding-left: 25px;
								&:before {
									/* font-family: "Material Icons";
									font-size: 22px;
									content: "\E5C8";
									position: absolute;
									left: 0;
									top: 0; */
								}
								span {
									color: $navy;
									text-transform: none;
									font-size: 13px;
								}
							}
						}
					}
					&.enrollment {
						.left {
							@include media($md) {
								@include span-columns(8);
							}
							ul {
								li {
									@include span-columns(3);
									@include omega(4n);
									&.compare {
										&:before {
											background-image: url("/assets/img/icons/icon-compare.svg");
										}
									}
									&.price {
										&:before {
											background-image: url("/assets/img/icons/icon-price.svg");
										}
									}
									&.ben {
										&:before {
											background-image: url("/assets/img/icons/icon-ben.svg");
										}
									}
									&.guide {
										&:before {
											background-image: url("/assets/img/icons/icon-guide.svg");
										}
									}
								}
							}
						}
						.right {
							@include media($md) {
								@include span-columns(4);
							}
							ul {
								li {
									@include span-columns(12);
									@include omega(1n);
								}
							}
						}
					}
					&.benefits {
						.left {
							@include media($md) {
								@include span-columns(6);
							}
							ul {
								li {
									@include span-columns(4);
									@include omega(3n);
								}
							}
						}
						.right {
							@include media($md) {
								@include span-columns(6);
							}
							ul {
								li {
									@include span-columns(6);
									@include omega(2n);
								}
							}
						}
					}
					&.resources {
						.left {
							@include media($md) {
								@include span-columns(6);
							}
							ul {
								li {
									@include span-columns(4);
									@include omega(3n);
								}
							}
						}
						.right {
							@include media($md) {
								@include span-columns(6);
							}
							ul {
								li {
									@include span-columns(6);
									@include omega(2n);
								}
							}
						}
					}
					&.quick {
						height: 310px;
						&:before {
							background-color: $haze;
						}
						.left {
							@include media($md) {
								@include span-columns(3);
							}
							ul {
								li {
									margin-top: 10px;
									text-align: left;
									&:before {
										display: none;
									}
								}
								ul {
									@include ulReset;
									li {
										margin-top: 0;
										position: relative;
										&:before {
											content: "\E89E";
											font-size: 18px;
											font-family: "Material Icons";
											position: absolute;
											left: 0;
											top: 0;
											display: block;
											color: $blue;
										}
										padding-left: 25px;
										text-align: left;
										margin: 10px 0;
										font-size: 14px;
										width: 100%;
										span.grayBlue {
											color: $blue;
										}
										a {
											color: $blue; //color:$grayBlue;
										}
									}
								}
							}
						}
						.right {
							@include media($md) {
								@include span-columns(9);
							}
							ul {
								li {
									@include span-columns(4);
									@include omega(3n);
									line-height: 1em;
									span {
										//color: white;
									}
								}
							}
						}
					}
					&:before {
						@include media($md) {
							content: "";
							width: 9999px;
							position: absolute;
							left: -4500px;
							height: 100%;
							top: 0;
							background-color: $lightYellow;
							z-index: -100;
						}
					}
					div {
						height: 100%;
						@include clearfix;
					}
				}
			}
		}
	}
	.foamButton {
		padding: 10px 20px;
		color: white !important;
		font-weight: bold;
		background-color: $teal;
		border-radius: 25px;
	}
	.findButton {
		border: 1px solid $navy;
		padding: 15px 20px;
	}
	/* div#search {
		width: 100%;
		@include searchStyling;
		display: none;
		margin-top: 77px;
		&:after {
			display: none;
		}
		@include media($md) {
			margin-top: 0;
		}
	} */
	nav#footer {
		//background-color: #f0f4f0;
		padding: 30px 0;
		color: $navy;
		.left {
			@include span-columns(3 of 4);
			@include media($md) {
			}
			ul {
				@include ulReset;
				margin-bottom: 30px;
				@include clearfix;
				li {
					@include media($xs) {
						@include span-columns(5);
						@include omega(2n);
					}
					@include media($md) {
						margin: 0 20px;
						width: auto;
						@include omega-reset(2n);
						&:first-child {
							margin-left: 0;
						}
					}
					margin: {
						top: 10px;
						bottom: 10px;
					}
					a {
						text-decoration: none;
						font-weight: bold;
						color: $grayBlue;
					}
				}
			}
			p {
				clear: both;
				color: $lightBlue;
				font-size: 12px;
				margin: 0.5em 0;
			}
		}
		.right {
			@include span-columns(1 of 4);
			@include media($md) {
			}
			img {
				max-width: 100%;
			}
		}
		.row {
			&.logo {
				padding-top: 50px;
				p {
					font-size: 13px;
				}
			}
			&.nav {
				@include media($md) {
					display: flex;
				}
				> * {
					flex: 1;
					padding-right: 20px;
				}
				div.left {
					text-align: center;
					.note {
						padding: 10px 20px;
						text-align: center;
						//background-color: #f04e53;
						background: rgb(251, 148, 22);
						background: linear-gradient(
							90deg,
							rgba(251, 148, 22, 1) 0%,
							rgba(251, 50, 58, 1) 100%
						);

						margin-top: 30px;
						p {
							color: white;
							&.phone {
								font-size: 15px;
								font-weight: bold;
							}
						}
					}
					img {
						max-width: 150px;
					}
				}
				> ul {
					@include ulReset;
					@include media($md) {
						//@include span-columns(3);
					}
					li {
						margin: 10px 0;
						font-size: 13px;
						padding: 0 25px 0 0;
					}
					> li {
						text-transform: uppercase;
						font-weight: bold;
						color: #3b5156;
						a {
							font-weight: normal;
							color: #3b5156;
						}
						> a {
							font-weight: bold;
						}
						ul {
							@include ulReset;
							li {
								margin: 9px 0;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
	}
	.homepageHero {
		background-image: url("/assets/img/Hero_Page_Image.jpg");
		background-size: cover;
		background-position: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include media($md) {
			min-height: 450px;
		}
		> div {
			width: 100%;
		}
		h1 {
			font-size: 48px;
			color: white;
		}
		p {
			color: white;
			a {
				background-color: $yellow;
				padding: 8px 40px;
				color: white;
				&:hover {
					@include katyButtonGradient;
				}
			}
		}
	}
	#homepage {
		@include clearfix; //margin-top: 70px;
		@include media($md) {
			margin-top: 0;
		}
		.content {
			//background-image: url("/assets/img/back-home.jpg");
			background-size: cover;
			background-attachment: fixed;
			background-color: #ebeced;
			@include clearfix;
			.announcement {
				padding: 30px 0;

				text-align: center;
				p {
					font-size: 38px;
					font-weight: 600;
				}
			}
			.search {
				text-align: center;
				.c {
					h4 {
						margin: 0;
						padding: 80px 0 20px 0;
						color: white;
						font-size: 48px;
						text-transform: none;
					}
					form {
						.box {
							box-shadow: 2px 2px 14px 0px rgba(92, 107, 126, 0.3);
							@include media($md) {
								@include span-columns(8);
								@include shift(2);
							}
							span.twitter-typeahead {
								width: 100%;
								text-align: left;
								.tt-menu {
									width: 100%;
									.tt-suggestion {
										background: white;
										width: 100%;
										padding: 5px 10px;
										cursor: pointer;
										&:hover {
											background-color: #fafafa;
										}
									}
								}
							}
							input[type="text"] {
								height: 120px;
								padding: 10px 0;
								display: block;
								font-size: 48px;
								font-size: 18px;
								font-weight: bold;
								width: 100%;
								margin: 0;
								padding: 0 20px;
								@include media($md) {
									margin: 0 auto;
									font-size: 36px;
									padding: 10px 30px;
									font-weight: 300;
								}
								@include media($lg) {
									font-size: 48px;
								}
							}
							input[type="submit"] {
								position: absolute;
								right: 0;
								top: 0;
								bottom: 0;
								background: $purple;
								border-radius: 0;
								font-family: "Material Icons";
								font-size: 48px;
								font-weight: normal;
								width: 120px;
							}
						}
					}
				}
			}
			.popular {
				@include clearfix;
				@include katyGradient;
				text-align: center;
				h4 {
					margin-bottom: 0;
					font-size: 18px;
					color: $grayBlue;
					@include font(sans-serif);
					font-weight: bold;
					text-transform: uppercase;
				}
				p.tags {
					font-size: 14px;
					max-width: 95%;
					text-transform: uppercase;
					margin: 0 auto;
					font-weight: bold;
					@include media($md) {
						font-size: 14px;
						max-width: 60%;
					}
					a {
						color: $grayBlue;
					}
				}
				.hero {
					.feature {
						@include media($md) {
							@include shift(2 of 12);
							@include span-columns(8 of 12);
							height: 400px;
							margin-top: 0;
						}
						@include media($xlg) {
							margin-top: 0;
						}
						a.link {
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 100;
						}
						.message {
							text-align: left;
							padding: 20px;
							@include clearfix;
							@include media($md) {
								width: 50%;
								float: left;
								height: 100%;
							}
							h2 {
								color: white;
								margin: 0;
							}
							h4 {
								margin-top: 0;
							}
							p {
								color: white;
							}
							a {
								color: #fef205;
							}
							i.material-icons {
								color: white;
								font-size: 28px;
								@include transform(rotate(-90deg));
							}
						}
						.shot {
							background-size: cover;
							background-position: 50%;
							height: 290px;
							width: 100%;
							@include media($md) {
								width: 50%;
								height: 100%;
								float: right;
							}
						}
					}
				}
			}
		}
		.slick-slide {
			height: 400px;
		}
		.feature {
			.slick-prev {
				position: absolute;
				left: -35px;
				color: white;
				cursor: pointer;
				top: 48%;
				font-size: 24px;
			}
			.slick-next {
				position: absolute;
				right: -35px;
				color: white;
				cursor: pointer;
				top: 48%;
				font-size: 24px;
			}
		}
	}
	#landing {
		.headline {
			@include clearfix;
			@include media($md) {
				padding-top: 100px;
			}
			.left {
				padding-top: 40px;
				padding-left: 0;
				@include media($md) {
					@include span-columns(4);
					padding: 0;
				}
				h1 {
					margin: 0;
				}
			}
			.right {
				padding: 40px 0;
				@include media($md) {
					@include span-columns(7);
					@include shift(1);
					padding: 0;
				}

				h4 {
					margin: 0;
					color: $text;
				}
				p {
					a {
						text-align: right;
						font-weight: bold;
						text-decoration: none;
						i.material-icons {
							position: relative;
							top: 5px;
						}
						&:hover {
							@include katyButtonGradient;
						}
					}
				}
			}
		}
		.boxes {
			position: relative;
			z-index: 100;
			@include clearfix;
			padding-bottom: 40px;
			@include media($md) {
				padding-top: 150px;
			}
			@include media($xlg) {
				padding-top: 120px;
			}
			.box {
				//@include clearfix;
				background: $lightYellow;
				height: 290px;
				display: table;
				margin: 15px 0;
				display: flex !important;
				flex-direction: column;
				@include media($md) {
					height: 350px;
				}
				&:nth-child(1) {
					h5 {
						@include katyGradient;
					}
				}
				&:nth-child(2) {
					h5 {
						background: rgb(145, 199, 255);
						background: linear-gradient(
							90deg,
							rgba(145, 199, 255, 1) 0%,
							rgba(19, 30, 221, 1) 80%
						);
					}
				}
				&:nth-child(3) {
					h5 {
						background: rgb(121, 11, 143);
						background: linear-gradient(
							90deg,
							rgba(121, 11, 143, 1) 0%,
							rgba(251, 110, 84, 1) 80%
						);
					}
				}
				&:nth-child(4) {
					h5 {
						background: rgb(31, 0, 159);
						background: linear-gradient(
							90deg,
							rgba(31, 0, 159, 1) 0%,
							rgba(31, 0, 0, 1) 80%
						);
					}
				}
				.box__arrow {
					width: 100%;
					display: block;
					position: absolute;
					bottom: 20px;
					margin: 0 auto;
					text-align: center;
					img {
						width: 30px;
					}
				}
				> a {
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 10;
				}
				h5 {
					position: relative;
					text-align: center;
					margin: 0;
					color: white;
					background-color: $grayBlue;
					padding: 0 10px;
					font-size: 30px;
					@include media($md) {
						display: block;
					}
					@include media($lg) {
						font-size: 30px;
					}
					span {
						display: inline-block;
						position: relative;
						@include media($md) {
							position: initial;
						}
						&:after {
							content: "";
							display: block;
							position: absolute;
							bottom: -60px;
							left: 42%;
							background-size: 100% 100%;
							@include media($md) {
								bottom: 15px;
							}
						}
					}
				}
				p {
					height: 100%;
					margin: 0;
					color: $text;
					text-align: center;
					width: 50%;
					padding: 0 10%;
					@include media($md) {
						display: block;
						width: 100%;
						height: 150px;
						padding-top: 40px;
					}
				}
			}
		}
		.secondary {
			background-color: #f8f9fa;
			padding: 30px 0;
			@include clearfix;
			.box {
				padding: 30px 60px;
				h6 {
					margin: 0;
					position: relative;
					padding-right: 50px;
					display: inline-block;
				}
				p {
					margin: 1em 0 0;
				}
				> a {
					width: 100%;
					height: 100%;
					position: absolute;
					z-index: 10;
					top: 0;
					left: 0;
				}
			}
		}
		&.landingWithLeft {
			.boxesWithLeft {
				.left {
					@include media($md) {
						@include span-columns(3);
						@include pad(0 20px 0 0);
					}
					h6 {
						font-size: 21px;
					}
				}
				.boxes {
					@include media($md) {
						@include span-columns(9);
					}
				}
			}
		}
	}

	#basics {
		//background-color: $gray;
		background-size: cover;
		background-attachment: fixed;
		#landing {
			.boxes {
				&.duo {
					.box {
						@include media($md) {
							@include span-columns(6);
						}
					}
				}
				.box {
					@include media($md) {
						@include span-columns(3);
						@include omega(4n);
					}
					h5 {
						color: white;
						padding: 20px;
						text-transform: uppercase;
						font-weight: bold;
						border-bottom: 5px solid white;
						min-height: 125px;
						display: flex;
						align-items: center;
						justify-content: center;
						/* span {
							&:after {
								content: "\E038";
								color: $purple;
								font-size: 48px;
								font-family: "Material Icons";
							}
						} */
					}
				}
			}
			.secondary {
				background-color: transparent;
				.box {
					background-color: $lightYellow;
					padding-left: 90px;
					&::before {
						content: "";
						background-image: url("/assets/img/icons/icon-next.png");
						background-size: 100%;
						width: 35px;
						height: 35px;
						position: absolute;
						display: block;
						left: 35px;
						top: 40px;
					}
					@include media($md) {
						@include span-columns(6);
						@include omega(2n);
						height: 350px;
					}
					h6 {
						color: $navy;
						font-size: 30px;
						text-transform: uppercase;
						font-weight: bold;
					}
					.subhead {
						font-size: 22px;
						color: $purple;
					}
					.subbox {
						padding-bottom: 30px;
						&:last-child {
							padding-bottom: 0;
						}
						.foamButton {
							font-size: 24px;
							text-transform: uppercase;
							&:hover {
								@include katyButtonGradient;
							}
						}
						a {
							/* display: block;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							z-index: 100; */
						}
					}
				}
			}
		}
		&.compare {
			.right {
				padding-top: 30px;
				@include priceBoxes;
				form {
					input[type="submit"] {
						float: right;
						background-color: $teal;
						&:hover {
							@include katyButtonGradient;
						}
					}
					.intro {
						@include clearfix;
						h4 {
							margin-bottom: 0;
							color: $purple;
						}
					}
					input[type="submit"] {
						margin: 20px 0;
					}
				}
				.priceBoxes {
					.box {
						cursor: pointer;
						@include media($md) {
							height: 120px;
						}
						@include media($lg) {
							height: 110px;
						}
						.selection {
							&:before {
								content: "";
							}
							@include span-columns(1);
							@include media($md) {
								@include span-columns(1);
							}
							input[type="checkbox"] {
								display: none;
							}
							span.check {
								width: 18px;
								height: 18px;
								display: block;
								background-image: url("/assets/img/icon-radio-inactive.svg");
								background-size: 100%;
								position: relative;
								top: 24px;
								left: 16px;
								&.active {
									background-image: url("/assets/img/icon-radio-active.svg");
								}
								@include media($md) {
									top: 43px;
									left: 35px;
								}
							}
						}
						.name {
							@include span-columns(10);
							@include omega(2n);
							@include media($md) {
								@include span-columns(4);
							}
							h6 {
								font-size: 20px;
								@include media($lg) {
									//font-size: 26px;
								}
							}
						}
						.description {
							@include span-columns(12);
							@include pad(0 20px);
							@include media($md) {
								@include span-columns(5.5);
								@include shift(0.5);
								@include pad(30px 10px 0 0);
							}
							@include media($xlg) {
								@include padding(30px 60px 0 0);
							}
							color: $purple;
						}
						h6 {
							color: $purple;
						}
					}
				}
			}
		}
	}
	#general {
		padding: 0;
		@include media($xs) {
			padding: 0 20px;
		}
		@include media($md) {
			padding-top: 80px;
		}
		@include media($lg) {
			padding-top: 100px;
		}
		&.prescription {
			h5 {
				margin-bottom: 0.5em;
			}
			.intro {
				p {
					font-size: 30px;
					@include font(serif);
					color: $blue;
					line-height: 1.4em;
				}
				div.title {
					@include media($xlg) {
						padding-bottom: 10px;
					}
					p {
						margin: 0;
					}
					img {
						width: 60px;
						height: 60px;
						margin-right: 25px; //margin-bottom: 30px;
						@include media($md) {
							float: none;
							display: block;
							margin-bottom: 20px;
						}
						@include media($lg) {
							float: left;
							display: inline;
							margin-bottom: 0;
							width: 80px;
							height: 80px;
						}
					}
					h4 {
						margin-top: 0;
						font-size: 24px;
						@include media($lg) {
							font-size: 30px;
							padding-left: 108px;
						}
					}
					h5 {
						font-size: 30px;
						@include media($lg) {
							font-size: 36px;
						}
					}
				}
			}
			p {
				&.compare {
					text-align: right;
					cursor: pointer;
					margin-top: 0;
					i {
						color: $teal;
						padding-right: 5px;
						position: relative;
						top: 5px;
					}
				}
			}
		}
		> .left {
			@include media($md) {
				@include span-columns(3);
			}
			h1 {
				margin-top: 0;
			}
			ul {
				@include ulReset;
				li {
					margin: 10px 0;
					a {
						color: $text;
						font-size: 16px;
						font-weight: bold;
					}
					&.here {
						a {
							color: $teal;
						}
					}
				}
			}
		}
		> .right {
			background-color: $lightYellow;
			padding: 20px 20px 80px 20px;
			@include media($md) {
				@include span-columns(8);
				@include shift(1);
				padding: 50px 40px 95px 95px;
				&:after {
					content: "";
					width: 9999px;
					height: 100%;
					background-color: $lightYellow;
					position: absolute;
					right: -9999px;
					top: 0;
					display: block;
				}
			}
			h5 {
				color: $navy;
			}
			ul {
				padding-left: 18px;
				li {
					margin: 15px 0;
					padding-left: 10px;
				}
			}
			.accordionBlock {
				.accordion {
					a {
						color: $teal;
						&.button {
							color: white;
						}
					}
				}
			}
		}
		> .full {
			background-color: $softGray;
			padding: 20px 30px;
		}
		&.landingLinks {
			.right {
				.box {
					@include clearfix;
					ul {
						@include ulReset;
						li {
							@include media($md) {
								@include span-columns(6);
								@include omega(2n);
								font-size: 24px;
								a {
									font-weight: 400;
									color: $navy;
								}
								margin: {
									top: 15px;
									bottom: 15px;
								}
							}
						}
					}
				}
			}
		}
		#documents {
			padding: 0;
			@include media($md) {
				padding: 0 20px;
			}
			&.find {
				.docContainer {
					p.action {
						padding-bottom: 20px;
						a {
							display: inline-block;
							border-radius: 20px;
							color: white;
							background-color: $teal;
							padding: 5px 15px;
							min-width: 140px;
							text-align: center;
							&:hover {
								@include katyButtonGradient;
							}
						}
					}
				}
			}
			.headline {
				@include clearfix;
				.left {
					@include media($md) {
						@include span-columns(4);
					}
				}
				.right {
					p {
						font-size: 24px;
						line-height: 1.4em;
						color: $blue;
						font-weight: 400;
						font-family: "bebas-neue", serif;
						@include media($md) {
							font-size: 30px;
						}
					}
					@include media($md) {
						@include span-columns(7);
						@include shift(1);
					}
				}
			}
			.docContainer {
				background-color: $lightYellow;
				padding: 0 20px;
				@include media($xs) {
					padding: 20px;
				}
				@include media($md) {
					margin-top: 50px;
					padding: 70px;
				}
				.row {
					@include clearfix;
					padding: 0 0 90px 0;
					@include media($md) {
						padding: 0 0 120px 0;
					}
					.left {
						@include media($md) {
							@include span-columns(4);
						}
						h5 {
							color: $blue;
							margin: 0;
							font-size: 30px;
							padding-top: 40px;
							@include media($md) {
								font-size: 36px;
								padding-top: 0;
							}
						}
					}
					.right {
						@include media($md) {
							@include span-columns(7);
							@include shift(1);
						}
						.accordionBlock {
							.accordion {
								border-bottom-color: $borderColor;
							}
						}
						ul {
							@include ulReset;
							li {
								position: relative;
								a {
									position: relative;
									display: inline-block;
									color: $navy;
									padding-left: 50px;
									&:before {
										content: "\E2C4";
										font-family: "Material Icons";
										font-size: 30px;
										color: $navy;
										position: absolute;
										left: 0;
										top: 0;
									}
								}
							}
						}
					}
				}
				&.forms {
					.row {
						padding: 30px 0;
						border-bottom: 1px solid #dadada;
						@include media($lg) {
							padding: 50px 0;
							width: 90%;
							margin: 0 auto;
						}
						.left {
							h5 {
								color: $teal;
							}
						}
					}
				}
			}
		}
	}
	#twoCol {
		padding: 0;
		@include media($md) {
			padding-top: 80px;
			padding-left: 20px;
			padding-right: 20px;
		}
		> .left {
			padding: 0 20px;
			@include media($md) {
				@include span-columns(3);
				padding: 0;
			}
			p {
				&.more {
					font-weight: bold;
					color: $teal;
					margin: 30px 0;
					@include media($md) {
						margin: 50px 0;
					}
					i {
						position: relative;
						top: 6px;
					}
				}
			}
			h6 {
				color: $grayBlue;
			}
			ul {
				@include ulReset;
				li {
					margin: 10px 0;
					color: $grayBlue;
					a {
						color: white;
						font-size: 16px;
						font-weight: bold;
					}
					&.here {
						a {
							color: $teal;
						}
					}
				}
			}
		}
		> .right {
			h4 {
				color: $purple;
			}
			background-color: $lightYellow;
			padding: 20px;
			/* @include media($xs) {
				padding:20px;
			} */
			p {
				&.compare {
					text-align: right;
					a {
						position: relative;
						display: inline-block;
						font-weight: bold;
						color: $grayBlue;
						i {
							font-size: 24px;
							color: $purple;
							position: relative;
							top: 5px;
							margin-left: 20px;
							&.purple {
								color: $purple;
							}
							&.teal {
								color: $teal;
							}
							&.grey {
								color: $lightBlue;
							}
						}
					}
				}
			}
			@include media($md) {
				@include span-columns(8);
				@include shift(1);
				padding: 95px 40px 95px 95px;
				&:after {
					content: "";
					width: 9999px;
					height: 100%;
					background-color: $lightYellow;
					position: absolute;
					right: -9999px;
					top: 0;
					display: block;
				}
			}
		}
	}
	#benefits {
		//background-color: $gray;
		background-size: cover;
		background-attachment: fixed;
		#landing {
			.boxes {
				.box {
					@include media($md) {
						@include span-columns(3);
						@include omega(4n);
					}
					h5 {
						color: white;
						padding: 20px;
						text-transform: uppercase;
						font-weight: bold;
						border-bottom: 5px solid white;
						min-height: 150px;
						display: flex;
						align-items: center;
						justify-content: center;
						span {
							/* &:after {
								content: "\E038";
								color: $purple;
								font-size: 48px;
								font-family: "Material Icons";
							} */
						}
					}
					p {
						padding-top: 15px;
						@include media($md) {
							font-size: 13px;
						}
						@include media($lg) {
							font-size: 15px;
						}
					}
				}
			}
			.secondary {
				padding: 0;
				.box {
					@include media($md) {
						margin-top: 30px;
						margin-bottom: 30px;
						@include span-columns(6);
						@include omega(2n);
					}
					h6 {
						color: $purple;
						&:after {
							content: "\E038";
							color: $purple;
							font-size: 28px;
							font-family: "Material Icons";
						}
					}
				}
			}
			&.landingWithLeft {
				.boxesWithLeft {
					@include media($md) {
						padding-top: 140px;
					}
					.left {
						margin: 20px 0 60px 0;
						@include media($md) {
							@include span-columns(3);
							@include pad(0 20px 0 0);
							margin: {
								top: 0;
								bottom: 0;
							}
						}
						h6 {
							color: $teal;
							margin: 0;
							font-weight: bold;
						}
					}
					.boxes {
						padding-top: 0;
						@include media($md) {
							@include span-columns(9);
						}
						.box {
							margin-top: 0;
							@include media($md) {
								@include span-columns(4);
								@include omega(3n);
							}
						}
					}
				}
			}
		}
		#benefit {
			padding-top: 40px; //margin-bottom: 40px;
			padding: 0;
			@include media($md) {
				padding: 80px 20px 0 20px;
			}
			@include media($lg) {
				padding: 60px 20px 0 20px;
			}
			@include clearfix;
			.details {
				@include clearfix;
				> .left {
					@include media($md) {
						@include span-columns(3.5);
					}
					h1 {
						@include media($md) {
							margin-top: 0;
						}
					}
					ul {
						@include ulReset;
						//padding-top: 40vh;
						li {
							margin: 20px 0;
							a {
								font-size: 16px;
							}
						}
					}
					p {
						color: $grayBlue;
					}
				}
				> .right {
					//background-color: $softGray;
					position: relative;
					z-index: 100;
					padding: 20px;
					@include media($md) {
						@include span-columns(8);
						@include shift(0.5);
						padding: 0 0 60px 0;
						/* &:after {
							content: "";
							width: 9999px;
							height: 100%;
							background-color: $softGray;
							position: absolute;
							right: -9999px;
							top: 0;
							display: block;
						} */
					}
					@include media($lg) {
						//padding: 0px 40px 95px 95px;
					}
					h5 {
						margin: 0;
						color: $navy;
						text-transform: uppercase;
						font-weight: bold;
						letter-spacing: 2px;
					}
					p {
						&.compare {
							text-align: right;
							cursor: pointer;
							a {
								color: $grayBlue;
								font-weight: normal;
								display: block;
							}
							i {
								color: $teal;
								padding-right: 5px;
								position: relative;
								top: 5px;
							}
						}
					}
					.intro {
						p {
							font-size: 30px;
							@include font(serif);
							color: $blue;
							line-height: 1.4em;
						}
						div.title {
							@include media($xlg) {
								padding-bottom: 10px;
							}
							p {
								margin: 0;
							}
							img {
								width: 60px;
								height: 60px;
								margin-right: 25px; //margin-bottom: 30px;
								@include media($md) {
									float: none;
									display: block;
									margin-bottom: 20px;
								}
								@include media($lg) {
									float: left;
									display: inline;
									margin-bottom: 0;
									width: 80px;
									height: 80px;
								}
							}
							h4 {
								margin-top: 0;
								font-size: 24px;
								@include media($lg) {
									font-size: 30px;
									padding-left: 108px;
								}
							}
							h5 {
								font-size: 30px;
								@include media($lg) {
									font-size: 32px;
								}
							}
						}
						a.findAnchor {
							text-align: right;
							display: block;
							color: $grayBlue;
							font-weight: normal;
							i {
								position: relative;
								top: 5px;
								color: $teal;
								padding-right: 5px;
							}
						}
					}
					.nobox {
						.description {
							@include media($lg) {
								padding-left: 115px;
							}
							@include media($xlg) {
								padding-left: 112px;
							}
							table {
								tr {
									td {
										padding: 5px;
									}
								}
							}
						}
						.row.total {
							td {
								padding-left: 0;
							}
						}
					}
					.boxes,
					.nobox {
						.box {
							background-color: $lightYellow;
							padding: 40px 10px;
							margin: 0 0 15px 20px;
							&:last-child {
								margin-bottom: 0;
							}
							@include media($xs) {
								padding: 20px 30px;
							}
							@include media($md) {
								//width: 125%;
								position: relative;
								//left: -20%;
							}
							div.title {
								@include clearfix;
								cursor: pointer;
								&:before {
									content: "\E5CF";
									font-weight: bold;
									font-family: "Material Icons";
									@include transform(rotate(0deg));
									@include transition(all, 0.2s);
									font-size: 24px;
									position: absolute;
									right: 0px;
									top: 0px;
								}
								&.active {
									&:before {
										@include transform(rotate(180deg));
									}
								}
								.image {
									@include span-columns(2 of 8);
									@include media($xs) {
										@include span-columns(1 of 8);
									}
									@include media($md) {
										@include span-columns(2);
									}
									@include media($lg) {
										@include span-columns(1);
									}
									img {
										width: 50px;
										height: 50px;
									}
								}
								.text {
									@include span-columns(6 of 8);
									@include media($xs) {
										@include span-columns(7 of 8);
									}
									@include media($md) {
										@include span-columns(10);
									}
									@include media($lg) {
										@include span-columns(11);
									}
									h6 {
										margin: 0;
										color: $purple;
										font-weight: 700;
									}
									p {
										margin: 0.3em 0;
										color: $purple;
									}
								}
							}
							div.data {
								display: none;
								.description {
									@include media($lg) {
										padding-left: 66px;
									}
									@include media($xlg) {
										padding-left: 80px;
									}
									table {
										width: 100%;
										tr {
											td {
												width: 50%;
												padding: 8px 0;
											}
										}
										&.headlines {
											margin-bottom: 80px;
											tr {
												td {
													&:first-child {
														vertical-align: top;
														color: $teal;
														font-weight: bold;
													}
												}
											}
										}
									}
								}
								ul {
									li {
										margin: 1em 0;
										line-height: 1.6em;
									}
								}
								h5 {
									margin-bottom: 0;
									margin-top: 60px;
									line-height: 28px;
									span {
										color: $grayBlue;
										font-size: 16px;
									}
								}
							}
							div.footer {
								p {
									font-size: 12px;
								}
							}
						}
						section {
							margin: 30px 0;
							table {
								width: 100%; //margin:0 30px;
								thead {
									background-color: $grayBlue;
									font-size: 24px;
									color: white;
									tr {
										th {
											text-align: left;
											font-weight: normal;
											padding: 20px 30px;
										}
									}
								}
								tbody {
									border-top: 1px solid #eeecea;
									tr {
										&.keyval {
											td {
												&:first-child {
													font-weight: bold;
													color: $teal;
												}
											}
										}
										&.arrayTitle {
											td {
												padding-top: 60px;
												&:first-child {
													font-weight: bold;
													color: $teal;
												}
											}
										}
										&.allBold {
											td {
												font-weight: bold;
											}
										}
										&.valueBold {
											td {
												&:last-child {
													font-weight: bold;
												}
											}
										}
										&:first-child {
											td {
												padding-top: 25px;
											}
										}
										&:last-child {
											td {
												padding-bottom: 40px;
											}
										}
										&.parent {
											td {
												&:first-child {
													font-weight: bold;
												}
											}
										}
										&.checks {
											td {
												&:first-child {
													color: $teal;
												}
											}
										}
										&.children {
											td {
												&:first-child {
													padding-left: 58px;
												}
											}
										}
										&.last {
											td {
												padding-bottom: 15px;
											}
										}
										/* &.noborder {
											td {
												border: none !important;
												font-weight: bold;
											}
										} */
										&.array {
											td {
												border-bottom: 0;
												color: $grayBlue; //font-weight:400;
												//padding:3px 0;
												&:first-child {
													width: 70%;
												}
												&:last-child {
													width: 30%;
													font-weight: bold;
												}
											}
										}
									}
									&.checkRow {
										tr.array {
											td {
												@include media($md) {
													padding-left: 50px;
												}
											}
										}
									}
								}
								tr {
									td {
										padding: 3px 0;
										width: 50%;
										padding: 5px 10px 5px 0px;
										@include media($xs) {
											padding: 5px 10px 5px 30px;
										}
										&:first-child {
											width: 70%;
										}
										&:last-child {
											width: 30%;
											text-align: left; //font-weight:bold;
										}
										&.title {
											text-align: left;
											color: $teal;
											padding: {
												top: 30px;
												bottom: 20px;
											}
											font-weight: bold;
										}
										span.check {
											display: inline-block;
											position: relative;
											padding-left: 26px;
											&:before {
												content: "";
												width: 16px;
												height: 12px;
												display: block;
												position: absolute;
												left: 0;
												top: 3px;
												background-image: url("/assets/img/check-teal.svg");
												background-size: 100% 100%;
											}
										}
										i {
											font-size: 1em;
											font-weight: 700;
											position: relative;
											top: 3px;
										}
										p {
											margin: 0;
										}
									}
								}
								&.special {
									thead {
										tr {
											th {
											}
										}
									}
									tbody {
										tr {
											td {
												color: $teal; //font-weight:bold;
												position: relative;
												padding: {
													top: 20px;
													bottom: 20px;
													left: 26px;
												}
												border-bottom: 1px solid #eeecea;
												&:last-child {
													//font-weight:bold;
													color: $grayBlue;
												}
											}
											&:first-child {
												td {
													padding-top: 30px;
												}
											}
										}
									}
								}
							}
						}
						ul {
							padding-left: 18px;
							li {
								padding-left: 10px;
							}
						}
						.description {
							padding-bottom: 60px;
							div.accordionBlock {
								padding: 40px 0 20px;
							}
						}
						form {
							div.jsonData {
								display: none;
							}
							label {
								font-size: 16px;
								margin: 0;
								font-family: "bebas-neue", Georgia, serif;
								color: #001837;
								width: 60%;
								display: block;
								padding: 30px 0 15px;
								@include media($md) {
									padding: 0 50px 0 0;
								}
							}
							ul.multi {
								@include ulReset;
								li {
									padding-left: 0;
									position: relative;
									cursor: pointer;
									&:before {
										content: "";
										width: 18px;
										height: 18px;
										position: absolute;
										display: block;
										left: -25px;
										top: 3px;
										background-image: url("/assets/img/icon-radio-inactive.svg");
										background-size: 100%;
									}
									&.active {
										&:before {
											background-image: url("/assets/img/icon-radio-active.svg");
										}
									}
								}
							}
							.ri {
								.select {
									float: none;
									width: 100%;
									margin: 5px 0;
								}
							}
							.row {
								@include media($md) {
									margin: 30px;
								}
								div.ri,
								div.salary,
								div.spouse_age,
								> .select {
									@include media($md) {
										display: block;
										@include span-columns(5);
									}
								}
								label {
									@include media($md) {
										@include span-columns(7);
									}
								}
							}
							input[type="number"] {
								width: 100%;
								height: 60px;
								font-weight: bold;
								padding-left: 40px;
								@include media($xs) {
									width: 60%;
								}
								@include media($md) {
									width: 100%;
								}
							}
							.select {
								width: 100%;
								@include media($xs) {
									width: 60%;
								}
								&:before {
									top: 18px;
								}
								select {
									width: 100%;
								}
							}
							.salary {
								&:before {
									content: "$";
									position: absolute;
									top: 31px;
									left: 27px;
									display: block;
									font-weight: bold;
									@include media($md) {
										top: 20px;
										left: 27px;
									}
								}
							}
							.total {
								p {
									color: $teal;
									margin-top: 50px;
									font-weight: bold;
									span {
										float: right;
										font-weight: bold;
										color: $grayBlue;
									}
								}
							}
						}
					}
					.footnotes {
						p {
							font-size: 11px;
						}
					}
				} // Copmare your Options page under Enrollment basics
				&.compareOptions {
					&.details {
						padding: 0;
					}
					#compare {
						display: block;
						background-color: transparent;
						&:before {
							background-color: $softGray;
						}
						&.hideDefault {
							display: none;
						}
						&:before {
							display: none;
						}
						> .c {
							background-color: $softGray;
						}
						.filters {
							padding-top: 20px;
							@include media($md) {
								padding: 0;
							}
							h4 {
								color: $purple;
							}
							.select {
								width: 100%;
								margin-top: 0;
								&:before {
									top: 18px;
								}
								@include media($md) {
									width: 400px;
									margin-top: 40px;
								}
								select {
									width: 100%;
								}
							}
						}
						.compareData {
							min-height: 500px;
							@include media($md) {
								//padding: 0 70px 90px 70px;
							}
							.desktop {
								display: none;
								@include media($md) {
									display: block; //display: none;
								}
								table {
									margin-top: 40px;
									width: 100%;
									&.count-2 {
										th {
											width: 33.33333%;
										}
									}
									&.count-3 {
										th {
											width: 25%;
										}
									}
									&.count-4 {
										th {
											width: 20%;
										}
									}
									thead {
										tr {
											background: $softGray;
											th {
												color: $purple;
												padding: 15px 20px;
												font-size: 21px;
												border-right: 1px solid $borderColor;
												&:first-child,
												&:last-child {
													border-right: none;
												}
											}
										}
									}
									tbody {
										tr {
											&:first-child {
												td {
													background: $grayBlue;
													text-align: left;
													padding: 15px 20px;
													color: white;
													font-size: 24px;
												}
											}
											&:last-child {
												td {
													border-bottom: none;
												}
											}
											td {
												text-align: center;
												font-weight: bold;
												font-size: 14px;
												padding: 25px 20px;
												border: {
													right: 1px solid $borderColor;
													bottom: 1px solid $borderColor;
												}
												&:first-child {
													text-align: left;
													font-weight: 400;
												}
												&:first-child,
												&:last-child {
													border-right: none;
												}
											}
										}
									}
								}
							}
							.mobile {
								@include media($md) {
									display: none;
								}
								table {
									tbody {
										tr {
											&:first-child {
												background-color: transparent;
												color: $grayBlue;
											}
											td {
												border-bottom: 0;
											}
										}
									}
								}
								.dyn {
									.left {
										h5 {
											color: white;
											background-color: $grayBlue;
											padding: 20px 30px;
											font-size: 24px;
											margin-bottom: 0;
										}
									}
									.right {
										h6 {
											font-size: 16px;
											color: $teal;
											padding: 0 20px;
										}
										.group {
											padding-bottom: 30px;
											.data {
												table {
													margin-top: 5px;
													tr {
														td {
															&:last-child {
																font-weight: bold;
															}
															padding: 8px 20px;
															font-weight: normal;
															color: $grayBlue;
														}
													}
												}
											}
										}
									}
								}
								#compareData {
									display: none;
								}
							}
							.left {
								h5 {
									font-size: 30px;
									@include media($lg) {
										font-size: 36px;
									}
								}
							}
							.right {
								.group {
									border-bottom: 1px solid $borderColor;
									h6 {
										color: $purple;
										max-width: 85%;
									}
									.data {
										table {
											tr {
												td {
													width: 40%;
													font-weight: bold;
													&:first-child {
														color: $teal;
														width: 60%;
													}
												}
											}
										}
									}
									&.list {
										h6 {
											color: $grayBlue;
											font-size: 18px;
											font-weight: 400;
											border-bottom: 0;
											cursor: auto;
											padding-bottom: 0;
											&:after {
												display: none;
											}
										}
										.data {
											display: block;
											&.critical {
												label {
													font-weight: bold;
												}
												.select {
													margin-left: 15px;
													&:before {
														top: 18px;
													}
												}
											}
										}
									}
									&.accord {
										h6 {
											cursor: pointer;
											&:after {
												display: block;
											}
										}
										.data {
										}
									}
								}
							}
							.jsonData {
								display: none;
							}
						}
					}
				}
			}
			&.medical {
				.right {
					.text {
						h6 {
							font-weight: normal !important;
						}
					}
				}
			}
			#compare {
				background-color: white;
				min-height: 100px;
				display: none;
				@include clearfix;
				@include media($md) {
					&:before {
						content: "";
						width: 9999px;
						height: 100%;
						background-color: white;
						position: absolute;
						left: -4500px;
						top: 0;
						display: block !important;
					}
				}
				p {
					margin: 0;
				}
				> .c {
					@include clearfix;
				}
				.filters {
					@include clearfix;
					@include media($md) {
						padding-top: 60px;
					}
					.left {
						@include media($md) {
							text-align: right;
							@include span-columns(6);
							@include pad(0 30px 0 0);
						}
					}
					.right {
						@include media($md) {
							@include span-columns(6);
						}
						.select {
							margin-top: 45px;
						}
					}
				}
				.compareData {
					@include clearfix;
					padding-bottom: 90px;
					table {
						margin-top: 40px;
						width: 100%;
						&.count-2 {
							th {
								width: 33.33333%;
							}
						}
						&.count-3 {
							th {
								width: 25%;
							}
						}
						&.count-4 {
							th {
								width: 20%;
							}
						}
						thead {
							tr {
								background: white;
								th {
									color: $purple;
									padding: 15px 20px;
									font-size: 21px;
									border-right: 1px solid $borderColor;
									&:first-child,
									&:last-child {
										border-right: none;
									}
								}
							}
						}
						tbody {
							tr {
								&:first-child {
									background: $grayBlue;
									text-align: left;
									padding: 15px 20px;
									color: white;
									font-size: 24px;
								}
								&:last-child {
									td {
										border-bottom: none;
									}
								}
								td {
									text-align: center;
									font-weight: bold;
									font-size: 14px;
									padding: 25px 20px;
									border: {
										right: 1px solid $borderColor;
										bottom: 1px solid $borderColor;
									}
									&:first-child {
										text-align: left;
										font-weight: 400;
									}
									&:first-child,
									&:last-child {
										border-right: none;
									}
								}
							}
						}
					}
				}
			}
		}
		.threeEight {
			.left {
				@include media($md) {
					@include span-columns(3);
				}
			}
			.right {
				@include media($md) {
					@include span-columns(8);
					@include shift(1);
				}
				.box {
					background: $softGray;
					padding: 90px 20px 90px 90px;
					ul {
						@include ulReset;
						li {
							margin: 15px 0;
							@include media($md) {
								margin: 0;
							}
						}
						&.teal {
							li {
								font-size: 28px;
								a {
									color: $teal;
								}
							}
						}
					}
				}
			}
		}
		.find {
			//background-color: $grayBlue;
			@include katyGradient;
			padding: 30px 40px;
			h1 {
				color: white;
				text-align: center;
				margin: 0;
				padding: 0;
			}
			.buttons {
				text-align: center;
				@include media($md) {
					@include shift(2);
					@include span-columns(8);
				}
				ul {
					@include ulReset;
					@include clearfix;
					display: inline-block;
					li {
						margin: 30px 0;
						text-align: center;
						font-weight: bold;
						@include media($md) {
							float: left;
							margin: 30px;
						}
						a {
							display: inline-block;
							color: white;
							background-color: $yellow;
							text-decoration: none;
							padding: 8px 35px;
							border-radius: 25px;
							border: 2px solid $yellow;
							text-align: center;
							font-size: 20px;
							&:hover {
								@include katyButtonGradient;
							}
						}
						span {
							display: block;
							text-align: center;
							color: #f0f4f0;
							opacity: 0.6;
							font-size: 16px;
							padding-top: 10px;
						}
					}
				}
			}
		}
		.wisely {
			background-color: $almostWhite;
			padding: 120px 0;
			.c {
				> .left {
					@include media($md) {
						@include span-columns(4);
					}
					p {
						color: $teal;
						font-size: 36px;
						margin: 0;
					}
				}
				> .right {
					@include media($md) {
						@include span-columns(7);
						@include shift(1);
					}
					.accordion {
						border-bottom: 1px solid $borderColor;
						.q {
							color: $grayBlue;
						}
					}
				}
			}
		}
		.medicalCompareBenefit {
			h1 {
				border-bottom: 3px solid $purple;
			}
		}
	}
	#price {
		//background-color: $gray;
		background-size: cover;
		background-attachment: fixed;
		h3 {
			color: $purple;
		}
		.c {
			.left {
				h6 {
					color: $grayBlue;
					margin: 50px 0 0.5em 0;
					font-weight: bold;
				}
				p {
					//color: $grayBlue;
					&.print {
						color: $purple;
						padding-left: 37px;
						position: relative;
						margin: 20px auto;
						width: 75%;
						display: block;
						&:before {
							content: "";
							width: 27px;
							height: 24px;
							position: absolute;
							display: block;
							top: 2px;
							left: 0;
							background-image: url("/assets/img/icon-print.svg");
						}
					}
					&.enroll {
						a {
							background-color: $purple;
						}
					}
				}
			}
			.right {
				h4 {
					@include media($md) {
						margin: 0;
					}
				}
				form {
					text-align: center;
					@include media($md) {
						text-align: left;
					}
				}
				.select {
					width: 100%;
					@include media($md) {
						display: inline-block;
						width: auto;
					}
					select {
						width: 100%;
						@include media($md) {
							width: 400px;
						}
					}
				}
				@include priceBoxes;
				input[type="submit"] {
					background: linear-gradient(
						90deg,
						rgba(251, 110, 84, 1) 0%,
						rgba(251, 110, 84, 1) 100%
					);
					transition: all 0.5s ease-out;
					margin-top: 50px;
					&:hover {
						background: linear-gradient(
							20deg,
							rgba(121, 11, 143, 1) 0%,
							rgba(251, 110, 84, 1) 70%
						);
					}
				}
			}
		}
		.step1 {
			.right {
				h3 {
					font-size: 24px;
					margin-top: 0;
					@include media($md) {
						font-size: 36px;
					}
				}
				h4 {
					margin: 1em 0;
				}
				div.who {
					display: none;
				}
				div.cover {
					p {
						margin-top: 50px;
						@include media($md) {
							display: inline-block;
						}
					}
					span {
						text-align: center;
						display: block;
						@include media($md) {
							text-align: left;
							display: inline-block;
						}
						input[type="radio"] {
							margin-left: 10px;
						}
						label {
							font-weight: bold;
						}
					}
				}
			}
		}
		.voluntary {
			.left {
				.calculator {
					.priceGroup {
						position: fixed;
						bottom: 0;
						left: 0;
						z-index: 500;
						width: 100%;
						@include media($md) {
							position: relative;
							bottom: auto;
							left: auto;
							z-index: 0;
						}
						.price {
							background-color: $grayBlue;
							width: 100%;
							padding: 15px 20px;
							@include media($md) {
								width: 240px;
								padding: 40px;
							}
							p {
								font-size: 36px;
								font-weight: 300;
								color: white;
								text-align: center;
								margin: 0;
								display: inline-block;
								@include media($md) {
									display: block;
									font-size: 44px;
								}
							}
							span {
								display: block;
								color: white;
								font-size: 14px;
								text-align: center;
								display: inline-block;
								float: right;
								padding-top: 25px;
								@include media($md) {
									float: none;
									display: block;
									padding-top: 0;
								}
							}
						}
						.priceOptions {
							padding: 15px 40px; //display:none;
							background-color: $lightBlue;
							@include media($md) {
								display: block;
								background-color: transparent;
								padding: 10px 0;
							}
							p.title {
								font-size: 16px;
								font-weight: bold;
								color: $grayBlue;
								position: relative;
								cursor: pointer;
								margin: 0;
								@include media($md) {
									margin: 1em 0;
									font-size: 21px;
								}
								&:after {
									content: "\E5CF";
									font-weight: bold;
									font-family: "Material Icons";
									@include transform(rotate(0deg));
									font-size: 24px;
									position: absolute;
									right: 5px;
									top: 0px;
									color: $grayBlue;
									@include transition(all, 0.2s);
									@include media($md) {
										//display: none;
										right: -20px;
									}
									@include media($lg) {
										right: 5px;
									}
								}
								&.active {
									&:after {
										@include transform(rotate(180deg));
									}
								}
							}
							ul {
								//display: none;
								@include media($md) {
									//display: block;
								}
								li {
									display: none;
									color: $grayBlue;
									font-size: 14px;
									margin: 0;
									font-weight: bold; //border-bottom: 1px solid $lightBlue;
									padding: 8px 0;
									@include media($md) {
										color: $grayBlue;
									}
									span {
										float: right;
										font-size: 14px;
									}
								}
							}
							.options {
								display: none;
								@include media($md) {
									display: block;
								}
								.option {
									border-bottom: 1px solid $lightBlue;
									padding: 5px 0 5px 0;
									margin: 10px 0;
									position: relative;
									cursor: pointer;
									&:after {
										content: "\E5CF";
										font-weight: bold;
										font-family: "Material Icons";
										@include transform(rotate(180deg));
										font-size: 24px;
										position: absolute;
										right: 15px;
										top: 10px;
										color: $grayBlue;
										@include transition(all, 0.2s);
									}
									&.active {
										&:after {
											@include transform(rotate(0deg));
										}
									}
									h6 {
										color: $grayBlue;
										font-size: 21px;
										margin: 0 0 10px 0;
										@include media($md) {
											color: $purple;
										}
									}
									table {
										width: 100%;
										display: none;
										thead {
											th {
												text-align: left;
											}
										}
										tbody {
											tr {
												td {
													padding: 5px 15px 5px 0;
													&:first-child {
														width: 65%;
													}
													&:last-child {
														width: 35%;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.right {
				.priceBoxes {
					.box {
						background: white;
						padding: 40px 30px;
						@include media($md) {
							padding: 40px 60px;
						}
						.jsonData,
						.hide {
							display: none;
						}
						.header {
							@include clearfix;
							cursor: pointer;
							&:before {
								content: "\E5CF";
								font-weight: bold;
								font-family: "Material Icons";
								@include transform(rotate(0deg));
								font-size: 24px;
								position: absolute;
								right: 0px;
								top: 0px;
								color: $grayBlue;
								@include transition(all, 0.2s);
							}
							&.active {
								&:before {
									@include transform(rotate(180deg));
								}
							}
						}
						.data {
							@include clearfix;
							display: none;
							padding-bottom: 60px;
							.right {
								@include omega(2n);
							}
						}
						.left {
							@include media($md) {
								@include span-columns(3.5);
							}
							h6 {
								margin: 0;
								color: $purple;
								font-size: 21px;
								@include media($lg) {
									font-size: 24px;
								}
							}
							p.view {
								margin-top: 50px;
								a {
									color: $teal;
									font-weight: bold;
									i {
										position: relative;
										top: 7px;
									}
								}
							}
						}
						.right {
							@include media($md) {
								@include span-columns(8);
								@include shift(0.5);
							}
							p.caption {
								font-size: 14px;
								margin: 0;
								max-width: 90%;
							}
							h5 {
								font-size: 30px;
								margin: 30px 0 20px 0;
								@include font(serif);
								color: $blue;
							}
							ul {
								@include ulReset;
								li {
									position: relative;
									padding-left: 30px;
									color: $teal;
									font-size: 21px;
									font-weight: 700;
									margin: 35px 0;
									cursor: pointer;
									&.disabled {
										opacity: 0.3;
									}
									span {
										display: block;
										font-size: 14px;
										color: $grayBlue;
										font-weight: 400;
									}
									&:before {
										content: "";
										width: 18px;
										height: 18px;
										position: absolute;
										display: block;
										left: 0;
										top: 5px;
										background-image: url("/assets/img/icon-radio-inactive.svg");
										background-size: 100%;
									}
									&.active {
										&:before {
											background-image: url("/assets/img/icon-radio-active.svg");
										}
									}
								}
							}
							select {
								option {
									&.special {
										display: none;
									}
								}
							}
							input[type="text"],
							input[type="number"] {
								background-color: $softGray;
								width: 100%;
								padding: 20px 15px;
								border: 0;
								outline: 0;
							}
							.select {
								width: 100%;
								select {
									width: 100%;
								}
							}
						}
						&.dental {
						}
						&.vision {
						}
						&.hfsa {
						}
						&.daycare {
						}
						&.add {
							.right {
								.select {
									width: 50%;
									display: block;
									&:before {
										top: 20px;
										color: $grayBlue;
									}
								}
							}
						}
						&.disabilty {
						}
						&.cancer {
						}
						&.indemnity {
						}
						&.critical {
						}
						&.accident {
						}
						&.legal {
						}
					}
				}
			}
		}
	}
	#talk {
		text-align: center;
		padding: 30px 20px;
		margin: 0 auto;
		background-color: $navy;
		h4 {
			margin: 0;
		}
		p {
			font-size: 24px;
			color: white;
		}
		a.num {
			font-weight: 400;
			color: $grayBlue;
		}
		a.button {
			padding: 10px 40px;
			color: $lightBlue;
			border: 2px solid $lightBlue;
			border-radius: 40px;
			text-decoration: none;
			font-weight: bold;
			margin-top: 10px;
			display: inline-block;
			background-color: transparent;
			@include transition(all, 0.2s);
			&:hover {
				background-color: $lightBlue;
				color: white;
			}
		}
	}
	.ben {
		&__landing__header {
			//background-image: url("/assets/img/2021-bg.jpg");
			background-position: center;
			background-size: cover; //overflow: hidden;
			@include media($md) {
				height: 360px;
			}
			> .c {
				height: 100%;
			}
			&--results {
				background-image: url("/assets/img/ben/MicrositeBen101_background_02.jpg");
				@include media($md) {
					//height: 260px;
				}
			}
		}
		&__landing__headerSearch {
			padding-bottom: 25px;
			@include media($md) {
				@include span-columns(9);
				@include shift(3);
				height: 100%;
				padding-bottom: 0;
				z-index: 100;
			}
			&:before {
				@include media($md) {
					content: "";
					background-image: url("/assets/img/ben/BenSuit.svg");
					background-repeat: no-repeat;
					width: 250px;
					height: 316px;
					position: absolute;
					left: -280px;
					bottom: 20px;
				}
			}
			h1 {
				color: $grayBlue;
				font-size: 60px;
				margin: 0;
				@include font(serif);
				margin-top: 50px;
				@include media($md) {
					font-size: 115px;
				}
			}
			h3 {
				color: $purple;
				font-size: 24px;
				margin: 0;
				margin-bottom: 20px;
				font-weight: 400;
				@include media($md) {
					margin: 0;
				}
			}
			form {
				.box {
					box-shadow: 2px 2px 14px 0px rgba(92, 107, 126, 0.3);
					@include media($md) {
						width: 500px;
						left: 5px;
						margin-top: 40px;
					}
					span.twitter-typeahead {
						width: 100%;
						text-align: left;
						.tt-menu {
							width: 100%;
							.tt-suggestion {
								background: white;
								width: 100%;
								padding: 5px 10px;
								cursor: pointer;
								&:hover {
									background-color: #fafafa;
								}
							}
						}
					}
					input[type="text"] {
						height: 50px;
						padding: 10px 0;
						display: block;
						font-size: 14px;
						font-weight: bold;
						width: 100%;
						margin: 0;
						padding: 0 20px;
						@include media($md) {
							font-size: 24px;
							margin: 0 auto;
							font-size: 24px;
							padding: 10px 30px;
							font-weight: 300;
						}
					}
					input[type="submit"] {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						background: $teal;
						border-radius: 0;
						font-family: "Material Icons";
						font-size: 24px;
						font-weight: normal;
						width: 60px;
						padding: 0 20px;
					}
				}
			}
			&--results {
				padding-bottom: 20px;
				@include media($md) {
					padding-bottom: 0;
					z-index: 0;
				}
				h1 {
					font-size: 48px;
					margin-top: 0;
					@include media($md) {
						font-size: 80px;
						margin-top: 50px;
					}
				}
				h3 {
					color: #001837;
					font-size: 24px;
					font-weight: bold;
				}
				&:before {
					width: 247px;
					height: 451px;
					position: absolute;
					left: -280px;
					bottom: -102px;
				}
			}
		}
		&__landing__intro {
			background-color: $softGray;
			padding: 5px 0 50px 0;
			.purple {
				color: #af3887;
				font-size: 1.7em;
				margin-top: 0;
			}
		}
		&__landing__introText {
			@include media($md) {
				@include clearfix;
				padding-bottom: 30px;
				@include span-columns(9);
				@include shift(3);
			}
		}
		&__ready {
			font-size: 24px;
			color: $grayBlue;
		}
		&__landing__list {
			@include ulReset;
		}
		&__landing__item {
			position: relative;
			margin: {
				top: 20px;
				bottom: 20px;
			}
			@include media($md) {
				@include span-columns(3);
				@include omega(4n);
			}
			> a {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 500;
			}
			> span {
				padding-bottom: 10px;
				display: block;
				font-size: 0.9em;
				font-weight: bold;
				color: $purple;
			}
		}
		&__landing__thumb {
			background-position: center;
			background-size: cover;
			width: 325px;
			height: 182px;
			@include media($md) {
				width: 100%;
				padding-bottom: 56.25%;
				height: auto;
			}
		}
		&__detail__header {
			@include bgFix;
			background-image: url("/assets/img/ben/MicrositeBen101_background_02.jpg");
			@include media($md) {
				height: 500px;
			}
			h1 {
				color: $blue;
				@include font(serif);
				font-weight: 400;
				padding-top: 30px;
				margin: 0;
				a {
					color: #001837;
					font-weight: 400;
				}
			}
			h2 {
				color: $teal;
				font-weight: 700;
				font-size: 24px;
				margin-top: 0;
				text-shadow: 0px 0px 15px rgba(256, 256, 256, 1);
			}
		}
		&__detail__video {
			width: 300px;
			height: 168px;
			@include media($xs) {
				width: 500px;
				height: 281px;
			}
			@include media($md) {
				width: 500px;
				height: 281px;
				margin-top: 10px;
				box-shadow: 0px 0px 20px #999999;
			}
			@include media($lg) {
				width: 640px;
				height: 360px;
				margin-top: 10px;
			}
		}
		&__detail__headerCont {
			@include media($md) {
				@include span-columns(10);
				@include shift(1);
			}
		}
		&__detail__leftHeader {
			@include media($md) {
				@include span-columns(7.5);
				@include shift(0.5);
			}
			@include media($lg) {
				@include span-columns(6.5);
				@include shift(0.5);
			}
			i {
				font-size: 28px;
				position: relative;
				top: -7px;
			}
			.ytcont {
				text-align: center;
				@include media($md) {
					text-align: left;
				}
			}
		}
		&__detail__rightHeader {
			display: none;
			@include media($md) {
				@include span-columns(4);
			}
			@include media($lg) {
				@include span-columns(4);
				float: right;
			}
			img {
				max-width: 100%;
				margin-left: -30px;
				margin-top: 20px;
				@include media($md) {
					margin-left: 0%;
					max-width: 100%;
					margin-top: 40%;
				}
			}
		}
		&__leftLink {
			display: none;
			@include media($md) {
				font-weight: 400;
				letter-spacing: 3px;
				text-transform: uppercase;
				display: block;
				position: absolute;
				top: 48%;
				left: 0;
				font-size: 30px;
				color: white;
				text-shadow: 0px 0px 15px rgba(0, 102, 153, 0.7);
				i {
					font-size: 48px;
					position: relative;
					top: 5px;
				}
			}
		}
		&__rightLink {
			display: none;
			@include media($md) {
				font-weight: 400;
				letter-spacing: 3px;
				text-transform: uppercase;
				display: block;
				position: absolute;
				top: 48%;
				right: 0;
				font-size: 30px;
				color: white;
				text-shadow: 0px 0px 15px rgba(0, 102, 51, 0.7);
				i {
					font-size: 48px;
					position: relative;
					top: 5px;
				}
			}
		}
		&__detail__cont {
			@include clearfix;
		}
		&__detail__cover {
			display: none;
			@include media($md) {
				background-color: white;
				text-align: center;
				position: absolute;
				top: 165px;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				display: block;
				background: -webkit-linear-gradient(
					rgba(250, 250, 250, 0) 0%,
					white 4%
				);
				background: -o-linear-gradient(rgba(250, 250, 250, 0) 0%, white 4%);
				background: linear-gradient(rgba(250, 250, 250, 0) 0%, white 4%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#ffffff',GradientType=0 );
			}
		}
		&__detail__readMore {
			background-color: $teal;
			border-radius: 20px;
			padding: 10px 20px;
			color: white;
			display: inline-block;
			cursor: pointer;
			margin-top: 30px;
		}
		&__detail__data {
			padding: 30px 0;
			clear: both;
			padding: 0;
			@include media($md) {
				height: 300px;
				overflow: hidden;
			}
		}
		&__detail__nav {
			text-align: right;
			color: $teal;
			font-weight: 400;
			display: none;
			@include media($md) {
				@include span-columns(2);
			}
			img {
				max-width: 100%;
				display: none;
				@include media($md) {
					display: block;
					margin-left: 30px;
				}
			}
			a {
				font-weight: normal;
				letter-spacing: 1px;
			}
			a,
			p {
				color: $teal;
			}
			p {
				margin: 0.5em 0;
				text-transform: uppercase;
			}
		}
		&__detail__copy {
			@include media($md) {
				@include shift(1);
				@include span-columns(9);
			}
			h2 {
				color: $teal;
				font-weight: bold;
				font-size: 24px;
			}
		}
		&__detail__recs {
			background-color: $softGray;
			padding: 30px 0;
			@include media($md) {
				margin-top: 30px;
			}
			p {
				color: $grayBlue;
				font-weight: bold;
			}
		}
		&__detail__sliderCont {
			@include media($md) {
				display: flex;
				border: {
					left: 1px solid $borderColor;
					right: 1px solid $borderColor;
				}
			}
			.slick-prev,
			.slick-next {
				&:before {
					content: "\f105";
					font-size: 18px;
					color: white;
					font-family: "FontAwesome";
					display: block;
					width: 20px;
					height: 20px;
					background-color: $teal;
					border-radius: 10px;
				}
			}
			.slick-prev {
				position: absolute;
				left: -100px;
				top: 20%;
				border: 0;
				background-color: transparent;
				outline: 0;
				&:before {
					content: "\f104";
				}
			}
			.slick-next {
				position: absolute;
				right: -100px;
				top: 20%;
				border: 0;
				background-color: transparent;
				outline: 0;
				&:before {
				}
			}
		}
		&__slide__thumb {
			@include bgFix;
			width: 100%;
			border: 1px solid $borderColor;

			min-height: 150px;
		}
		&__detail__slide {
			margin-left: 10px;
			margin-right: 10px;
			flex: 1;
			&.slick-center {
				.ben__slide__thumb {
					min-height: 180px;
				}
			}
			margin: {
				left: 15px;
				right: 15px;
			}
			span {
				padding-top: 15px;
				display: block;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 500;
			}
		}
		&__results__cont {
			background-color: $softGray;
			padding: 20px 0;
		}
		&__results__results {
			@include media($md) {
				@include span-columns(8);
				@include shift(3);
			}
			h3 {
				color: $teal;
				font-size: 1.7em; //font-weight: bold;
			}
		}
		&__results__entry {
			@include clearfix;
			padding: 20px 0;
			border-bottom: 1px solid white;
			.left {
				@include media($md) {
					@include span-columns(4);
				}
			}
			.right {
				@include media($md) {
					@include span-columns(8);
				}
			}
			h4 {
				margin: 0;
				color: $teal;
				@include font(sans-serif);
				font-size: 20px;
				font-weight: bold;
				padding-top: 15px;
				@include media($md) {
					padding-top: 0;
				}
			}
			a {
				@include aCover;
			}
		}
	}
	#basics,
	#general,
	#benefits {
		//margin-top: 75px;
		@include media($md) {
			margin-top: 0;
		}
		.iconLine {
			p {
				a {
					display: block;
					@include media($md) {
						display: inline;
					}
					padding-right: 30px;
				}
			}
		}
		.left {
		}
		.right {
			ul {
				li {
					line-height: 1.6em;
					margin: 20px 0;
				}
			}
		}
		#landing {
			&.trio {
				.boxes {
					.box {
						@include media($md) {
							@include shift(0.5);
						}
						&:first-child {
							@include media($md) {
								@include shift(1);
							}
						}
					}
				}
			}
			&.quad {
				.boxes {
					.box {
						@include media($md) {
							@include span-columns(3);
						}
					}
				}
			}
			&.double {
				.boxes {
					.box {
						@include media($md) {
							@include shift(1);
						}
						&:first-child {
							@include media($md) {
								@include shift(2.5);
							}
						}
					}
				}
			}
		}
	}
	#searchResults {
		.searchBar {
			margin-top: 68px;
			@include transition(all, 0.5s); //height:168px;
			@include media($xs) {
				margin-top: 77px;
			}
			@include media($md) {
				margin-top: 0; //height:168px;
			}
			background-color: rgba(255, 255, 255, 0.6);
			&.active {
				@include media($xs) {
				}
				@include media($md) {
					//height:200px;
				}
				@include media($lg) {
				}
				@include media($xlg) {
				}
			}
			form {
				.box {
					&:before {
						width: 21px;
						height: 21px;
						content: "";
						display: block;
						position: absolute;
						left: 0;
						top: 26px;
						background-image: url("/assets/img/search-thick.svg");
						@include media($xs) {
							width: 36px;
							height: 36px;
							top: 24px;
						}
						@include media($md) {
							width: 64px;
							height: 64px;
							top: 50px;
							background-image: url("/assets/img/search.svg");
						}
					}
					input[type="text"] {
						border: 0;
						background-color: transparent;
						padding-left: 30px;
						font-size: 18px;
						font-weight: 400;
						color: #7b889b;
						margin-top: 15px;
						width: 100%;
						@include media($xs) {
							font-size: 26px;
							margin-top: 20px;
							padding-left: 52px;
						}
						@include media($sm) {
							font-size: 42px;
							margin-top: 28px;
							font-weight: 300;
						}
						@include media($md) {
							font-size: 54px;
							padding-left: 110px;
							margin-top: 8px;
							height: 160px;
						}
						@include media($lg) {
							font-size: 66px;
							margin-top: 0;
						}
						@include media($xlg) {
							font-size: 72px;
						}
					}
					input[type="submit"] {
						display: none;
					}
					span.twitter-typeahead {
						width: 100%;
						pre {
							background-color: red;
						}
						.tt-menu {
							margin-left: 120px;
							font-size: 24px;
							cursor: pointer;
							width: 100%;
							/* background-color: white;
							width: 100%;
							border: {
								bottom: 1px solid $borderColor;
								left: 1px solid $borderColor;
								right: 1px solid $borderColor;
							} */
							//max-width:600px;
							.tt-dataset {
								.tt-suggestion {
									padding: 10px 15px;
									background-color: white;
									width: 100%;
									&:hover {
										background: $almostWhite;
									}
								}
							}
						}
					}
				}
			}
		}
		.resultsBox {
			.c {
				padding: 0;
				@include media($md) {
					padding: 0 20px;
				}
			}
			.numResults {
				padding: 25px 82px;
				@include media($md) {
					@include span-columns(10);
					@include shift(1);
				}
				span {
					font-size: 36px;
					color: $blue;
					@include font(serif);
				}
			}
			.results {
				background-color: white;
				padding: 0 20px;
				@include media($md) {
					@include span-columns(10);
					@include shift(1);
					@include pad(100px);
				}
				.entry {
					h3 {
						font-size: 36px;
						a {
							font-weight: 400;
						}
						i {
							margin-right: 10px;
						}
					}
					padding: 30px 0;
					border-bottom: 1px solid $almostWhite;
				}
			}
		}
	}
	#print {
		.bg {
			background: $softGray !important;
			.logo {
				width: 283px;
				height: 36px;
				margin: 100px 0 50px 0;
			}
			h1 {
				margin: 0.2em 0;
			}
			#benefit {
				padding-top: 0;
			}
		}
	}
	#blog {
		> .headline {
			@include clearfix;
			clear: both;
			@include media($md) {
				@include span-columns(9);
			}
			h1 {
				margin-bottom: 0;
			}
			h4 {
				margin-top: 10px;
			}
		}
		.blogContainer {
			clear: both;
			@include clearfix;
			.headline {
				@include clearfix;
				.first {
					@include media($md) {
						@include span-columns(9);
					}
					article {
						height: 550px;
						width: 100%;

						@include media($md) {
							height: 400px;
						}
					}
				}
				> nav {
					background-color: $softGray;
					padding: 15px 20px;
					@include media($md) {
						@include span-columns(3);
					}
					form {
						.searchBox {
							&:before {
								content: "\E8B6";
								width: 30px;
								height: 30px;
								font-family: "Material Icons";
								display: block;
								position: absolute;
								right: 10px;
								top: 10px;
								font-size: 20px;
							}
						}
						input[type="text"] {
							padding-right: 45px;
						}
						input[type="submit"] {
							display: block;
							position: absolute;
							right: 0;
							top: 0;
							background-color: transparent;
							height: 100%;
							width: 50px;
						}
					}
					span.title {
						padding-top: 20px;
						display: block;
						color: $teal;
						font-size: 20px;
					}
					ul {
						@include ulReset;
						padding-top: 10px;
						li {
							margin: 5px 0;
							a {
								color: $grayBlue;
								font-weight: 400;
							}
						}
					}
				}
			}
			.blogBlock {
				@include clearfix;
			}
			.mobileContainer {
				display: none;
				position: fixed;
				max-height: 100%;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 300;
				background-color: rgba(92, 107, 126, 0.95);
				overflow-y: scroll;
				.close {
					text-align: right;
					color: white;
					padding: 20px 30px;
				}
				.data {
					padding: 20px 30px;
					title {
						display: block;
						color: white;
						font-size: 30px;
						font-weight: bold;
					}
					p,
					h2,
					h3 {
						color: white;
					}
				}

				@include media($md) {
					display: none;
				}
			}
			article {
				position: relative;
				background-size: cover;
				background-position: center center;
				height: 320px;

				margin: {
					top: 10px;
					bottom: 10px;
				}

				@include media($md) {
					width: 50%;
					float: left;
					&:nth-child(6n + 1) {
						width: 55%;
					}
					&:nth-child(6n + 2) {
						width: 43%;
						margin-left: 2%;
					}
					&:nth-child(6n + 3) {
						width: 49%;
					}
					&:nth-child(6n + 4) {
						width: 49%;
						margin-left: 2%;
					}
					&:nth-child(6n + 5) {
						width: 55%;
					}
					&:nth-child(6n + 6) {
						width: 43%;
						margin-left: 2%;
					}
				}

				&.text {
					background-color: $teal;
					padding: 25px 35px;
					h2,
					h3 {
						color: black;
						margin: 0.1em;
					}
					h2 {
						font-size: 30px;
						@include media($md) {
							font-size: 48px;
						}
					}
					h3 {
						font-size: 30px;
						@include media($md) {
							font-size: 48px;
						}
					}
					p {
						color: white;
						font-size: 15px;

						@include media($md) {
							font-size: 21px;
						}
						a {
							color: white;
							font-weight: bold;
						}
					}
				}
				.data {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					background-color: rgba(92, 107, 126, 0.95);
					padding: 15px;
					title {
						color: white;
						font-size: 22px;
						display: block;
					}
					.copy {
						display: none;
						color: white;
						p {
							color: white;
						}
					}
				}
			}
		}
	}
	.boxesContainer {
		@include media($md) {
			display: flex;
		}
		.boxes__image {
			flex: 1;
			background-size: cover;
			max-height: 240px;
		}
		.boxes {
			flex: 3;
			.box {
				.title {
					@include media($md) {
						display: flex;
					}
					.image {
						margin-right: 40px !important;
					}
				}
			}
		}
	}
	/* Generic Page outside basics/benefits */
	.bg {
		//background-image: url("/assets/img/2021-bg.jpg");
		background-size: cover;
		background-attachment: fixed;
		//background-color: #e8e9e7;
		background-color:white;
	}
	.right {
		h4 {
			color: $purple;
		}
	}
	.hbspt-form {
		label {
			font-weight: bold;
			display: inline-block;
			padding: 10px 0;
		}
		ul {
			@include ulReset;
			li {
			}
		}
		input[type="checkbox"] {
			margin-right: 15px;
		}
		.hs-richtext {
			padding: 30px 0;
			font-weight: bold;
			font-size: 24px;
		}
		input[type="submit"] {
			margin-top: 50px;
			background-color: $teal;
		}
	}
	#notification {
		background-color: $purple;
		.c {
			text-align: center;
			padding: 10px 60px;
			p {
				margin: 0;
				color: white;
				i.material-icons {
					position: relative;
					top: 6px;
					padding-right: 1px;
				}
				a {
					color: white;
				}
			}
		}
		button.close {
			display: none;
			position: absolute;
			right: 0;
			top: 0;
			width: 150px;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.1);
			border: 0;
			color: white;
			font-weight: bold;
			outline: 0;
			font-size: 16px;

			@include media($md) {
				display: block;
			}
			i {
				font-size: 16px;
				font-weight: bold;
				position: relative;
				top: 3px;
				padding-right: 10px;
			}
		}
	}
	/* Section Styling */
	&.homepage {
		nav#subnav {
			img.searchIcon {
				display: none;
			}
		}
	}
	&.search {
		#subnav {
			img.searchIcon {
				display: none;
			}
		}
	}
	&.enrollment-basics {
		a,
		h5,
		h6 {
			color: $text;
		}
		nav#main {
			ul {
				li {
					&.here {
						> a {
							color: $teal;
						}
					}
				}
			}
		}
		div.bg {
			//background-color: $gray;
			//background-image: url("/assets/img/back-basics.jpg") !important;
			background-size: cover;
			background-attachment: fixed;
		}
		#benefits {
			background-image: none;
		}
	}
	&.plan-benefits {
		a,
		h5,
		h6 {
		}
		nav#main {
			ul {
				li {
					&.here {
						> a {
							color: $teal;
						}
					}
				}
			}
		}
		div.bg {
			//background-color: $gray;
			background-size: cover;
			background-attachment: fixed;
		}
		#general {
			div.right {
				padding-top: 0;
				background-color: $lightYellow;
			}
		}
		div.left {
			#nav-sub {
				li {
					//margin:20px 0 !important;
				}
			}
		}
	}
	/* Global Styles */
	.accordion {
		border-bottom: 1px solid $borderColor;
		&:after {
			content: "\E5CF";
			font-weight: bold;
			font-family: "Material Icons";
			@include transform(rotate(0deg));
			font-size: 24px;
			position: absolute;
			right: 20px;
			top: 30px;
			color: $grayBlue;
			@include transition(all, 0.2s);
		}
		&.active {
			&:after {
				@include transform(rotate(180deg));
			}
		}
		.q {
			color: $navy;
			padding-right: 30px;
			font-size: 18px;
			padding: 30px 0 20px;
			cursor: pointer;
			max-width: 90%;
			@include media($md) {
				font-size: 24px;
			}
		}
		.a {
			display: none;
			padding-bottom: 20px;
			max-width: 90%;
			table {
				tr {
					td {
						padding: 10px 15px;
					}
				}
			}
		}
	}
	.select {
		font-size: 16px;
		position: relative;
		display: inline-block;
		background-color: $softGray;
		&.white {
			background-color: white;
			select {
				//background-color: white;
			}
		}
		&:before {
			content: "\E5CF";
			font-weight: bold;
			font-family: "Material Icons";
			@include transform(rotate(0deg));
			font-size: 24px;
			position: absolute;
			right: 20px;
			top: 18px;
		}
		select {
			outline: none;
			-webkit-appearance: none;
			display: block;
			padding: 1.2em 3em 1.3em 1.5em;
			margin: 0;
			transition: border-color 0.2s;
			border: none;
			font-weight: bold;
			background: transparent;
			color: $navy;
			line-height: normal;
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			position: relative;
			z-index: 100;
		}
	}
	.c {
		@include outer-container;
		@include padding(0 20px);
		@include media($md) {
			max-width: 992px;
		}
		@include media($lg) {
			max-width: 1170px;
		}
		@include media($xlg) {
			max-width: 1400px;
		}
		.left {
			// This is for the nav on the left sides of interior - general etc
			ul#nav-sub {
				display: none;
				@include media($md) {
					display: block;
				}
				li {
					text-transform: uppercase;
					position: relative;
					a {
						color: $navy;
						font-weight: normal;
					}
					&.here {
						color: $navy;
						&:before {
							content: "\E5C8";
							font-size: 18px;
							font-family: "Material Icons";
							position: absolute;
							left: -25px;
							top: 0;
							font-weight: bold;
							display: block;
							color: white;
							background-color: #f04e53;
							text-transform: none;
						}
						a {
							font-weight: bold;
						}
					}
				}
			}
		}
	}
	.row {
		@include row;
	}
	.hideEle {
		display: none;
	}
	.browserError {
		position: absolute;
		background-color: #6f1919;
		color: white;
		text-align: center;
		width: 80%;
		top: 0px;
		left: 10%;
		height: 100px;
		z-index: 9999;
		p {
			font-size: 24px;
			a {
				color: white;
			}
		}
	}
	div,
	header,
	footer {
		position: relative;
	}
	h1 {
		font-weight: 100;
		font-size: 48px;
		color: $text;
		padding-top: 30px;
		padding: 20px 20px 0 0px;
		margin-top: 0;
		@include media($md) {
			padding: 0;
			margin: 0 0 1em 0;
			font-size: 34px;
			font-weight: bold;
			padding-bottom: 25px;
		}
		@include media($lg) {
			&:after {
				left: -85vw;
				width: 100vw;
			}
		}
		&.diagonal {
			@include media($md) {
				position: relative;
				z-index: 10;
				span {
					color: $navy;
					position: relative;
				}

				&:after {
					content: "";
					display: block;
					background-color: #f04e53;
					position: absolute;
					bottom: 0;
					left: -77vw;
					width: 100vw;
					height: 6px;
				}
			}
			@include media($lg) {
				&:after {
					left: -85vw;
					width: 100vw;
				}
			}
			@media (max-height: 800px) {
				&:before {
					height: 45vh;
				}
			}
			@include media($xlg) {
			}
		}
	}
	h2 {
		font-size: 48px;
		font-weight: 300;
		color: #5c6b7e;
		@include media($md) {
			font-size: 54px;
		}
	}
	h3 {
		font-size: 48px;
		font-weight: 300;
		color: #00adee;
	}
	h4 {
		font-size: 24px;
		line-height: 1.4em;
		color: $navy;
		font-weight: 400;
		font-family: "bebas-neue", serif;
		@include media($md) {
			font-size: 30px;
		}
	}
	h5 {
		font-size: 32px;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight:bold;
	}
	h6 {
		font-size: 24px;
		color: #78a34c;
		font-weight: 400;
		margin: 20px 0;
	}
	p {
		line-height: 1.6em;
		font-size: 16px;
	}
	ul {
		li {
			font-size: 16px;
		}
	}
	hr {
		border: 0;
		background-color: $borderColor;
		height: 1px;
		margin: 40px 0;
	}
	a {
		text-decoration: none;
		outline: 0;
		font-weight: bold;
		color: $teal;
		&.button {
			border: 0;
			color: white;
			padding: 10px 30px;
			font-size: 18px;
			border-radius: 20px;
			font-weight: bold;
		}
	}
	.button {
		display: inline-block;
		border-radius: 20px;
		color: white;
		background-color: #97a6b9;
		padding: 5px 15px;
		text-align: center;
	}
	span {
		&.teal {
			color: $teal;
		}
		&.grayBlue {
			color: $grayBlue;
		}
		&.White {
			color: white;
		}
	}
	fieldset {
		border: 0;
	}
	form {
		input[type="text"],
		input[type="tel"],
		input[type="email"],
		input[type="password"],
		input[type="search"],
		input[type="number"],
		textarea {
			width: 80%;
			border: 0;
			padding: 10px;
			outline: 0;
			margin: 10px 0;
			@include media($md) {
				width: 100%;
				margin: 0;
			}
		}
		select {
			margin: 10px 0;
			min-width: 200px;
			@include media($md) {
				margin: 0;
				min-width: 0;
			}
		}
		textarea {
			margin: 30px 0;
			height: 125px;
		}
		input[type="submit"],
		button {
			border: 0;
			color: white;
			padding: 10px 30px;
			font-size: 18px;
			border-radius: 20px;
			font-weight: bold;
		}
	}
	table {
		&.equalDivide {
			tr {
				td {
					width: 50%;
				}
			}
		}
		tr {
			td {
				font-size: 14px;
				p {
					font-size: 14px;
				}
			}
		}
	}
	sup {
		&.note {
			font-size: 12px;
			padding: 20px 0;
			display: block;
			@include media($md) {
				padding-top: 20px;
				float: right;
			}
		}
	}

	// Years colors
	.grape {
		color: #532e63;
	}
	.green {
		color: #326e36;
	}
	.blue {
		color: #1986c7;
	}
	.foundation {
		color: #3b5156;
	}
	.orange {
		color: #fc8c02;
	}
	.lemon {
		color: #f7bb2b;
	}
	.salmon {
		color: #f04e53;
	}
	.support {
		color: #e5ddcf;
	}
}

.tiny {
	font-size: 11px;
}
