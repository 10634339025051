@mixin rounded($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

// Box shadow
@mixin shadow($x, $y, $blur, $color) {
    -webkit-box-shadow: $x $y $blur $color;
    -moz-box-shadow: $x $y $blur $color;
    box-shadow: $x $y $blur $color;
}

// Inset Shadow
@mixin shadow-inset($x, $y, $blur, $color) {
    -webkit-box-shadow: inset $x $y $blur $color;
    -moz-box-shadow: inset $x $y $blur $color;
    box-shadow: inset $x $y $blur $color;
}

// Background Opacity
@mixin background-opacity($color, $opacity) {
    background: $color;
    /* The Fallback for IE etc*/
    background: rgba($color, $opacity);
}

// CSS 3 Transition
@mixin transition($property, $timing) {
    -webkit-transition: $property $timing ease;
    -moz-transition: $property $timing ease;
    -o-transition: $property $timing ease;
    transition: $property $timing ease;
}

// CSS3 Transitions

/* Standard */

@mixin transition($property, $timing) {
    -webkit-transition: $property $timing ease;
    -moz-transition: $property $timing ease;
    -o-transition: $property $timing ease;
    transition: $property $timing ease;
}



/* Ease Out */

@mixin transition_easeout($property, $timing) {
    -webkit-transition: $property $timing ease-out;
    -moz-transition: $property $timing ease-out;
    -o-transition: $property $timing ease-out;
    transition: $property $timing ease-out;
}

// CSS3 Animations

/* Width */

@mixin width($duration) {
    -webkit-transition: width $duration, -webkit-transform $duration;
    /* Safari */
    -moz-transition: width $duration, -moz-transform $duration;
    /* Fx 5+ */
    -o-transition: width $duration, -o-transform $duration;
    /* Opera 12+ */
    transition: width $duration, transform $duration;
    /* IE 10+ */
}



/* Opacity From-To */

@mixin opacity_trans($animname, $from, $to) {
    @-webkit-keyframes $animname {
        from {
            opacity: $from;
        }
        to {
            opacity: $to;
        }
    }
    @-moz-keyframes $animname {
        from {
            opacity: $from;
        }
        to {
            opacity: $to;
        }
    }
    @-o-keyframes $animname {
        from {
            opacity: $from;
        }
        to {
            opacity: $to;
        }
    }
    @keyframes $animname {
        from {
            opacity: $from;
        }
        to {
            opacity: $to;
        }
    }
}

@mixin font-smoothing($value: on) {
    @if $value==on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin font($type) {
    @if $type==sans-serif {
        font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
    }
    @else if $type==serif {
        font-family: "utopia-std", Georgia, serif;
    }
}

@mixin text-gradient($dir, $start, $end) {
    // Mixin for text gradients.  Only works on Webkit at the moment :(
    color: $start;
    @media (-webkit-min-device-pixel-ratio: 0) {
        @include linear-gradient(to $dir, $start, $end);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@mixin sprite($x, $y) {
    background-image: url(/assets/img/sprite.png);
    background-position: (-(100 * $x))+px (-(100 * $y))+px;
}

@mixin remove-gradient() {
    -webkit-text-fill-color: initial;
    background-image: none;
    background-color: transparent;
    -webkit-background-clip: initial;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin font-type($type) {
    @if $type==sans-serif {
        font-family: 'azo-sans-web', Arial, Helvetica, sans-serif;
    }
    @else if $type==serif {
        font-family: "adobe-garamond-pro", Georgia, serif;
    }
}

@mixin more-link() {
    background: $ecblue;
    padding: 12px 30px;
    text-align: center;
    display: inline-block;
    color: white;
    text-decoration: none;
    @include font-type(sans-serif);
    font-size: 13px;
    font-weight: bold;
    &:hover {
        color: rgba(255, 255, 255, .5);
    }
}

@mixin omega-reset($nth) {
    &:nth-child(#{$nth}) {
        margin-right: flex-gutter($grid-columns, $gutter);
    }
    &:nth-child(#{$nth}+1) {
        clear: none
    }
}

@mixin ulReset() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin leftHanger($color) {
    &:before {
        content: '';
        width: 9999px;
        height: 100%;
        position: absolute;
        left: -9999px;
        top: 0;
        background: $color;
    }
}

@mixin rightHanger($color) {
    &:after {
        content: '';
        width: 9999px;
        height: 100%;
        position: absolute;
        right: -9999px;
        top: 0;
        background: $color;
    }
}

@mixin rotateObj($deg, $duration, $fill) {
    @include keyframes(rotateCircle) {
        to {
            @include transform(rotate($deg));
        }
    }
    @include animation(rotateCircle $duration $fill);
}

@mixin intro() {
    @include linear-gradient(270deg, #9c5314, #fc851e); //9c5314
    //background: linear-gradient(270deg, #4a6624, #4d177a);
    background-size: 400% 400%;
    @include animation(gradientMove 10s linear infinite);
    padding: 30px 20px;
    @include media($md) {
        padding: 30px 0;
    }
    p {
        margin: 0;
        font-size: 16px;
        color: white;
        font-weight: 300;
    }
}

@mixin marginFlip() {
    margin: 0;
    padding: 1em 0;
}

@mixin linkFill() {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
}

@mixin caret() {
    content: 'expand_more';
    font-weight: bold;
    font-family: 'Material Icons';
    @include transform(rotate(0deg));
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    color: $grayBlue;
    @include transition(all, 0.2s);
}

@mixin searchStyling() {
    @include clearfix;
    height: 90px;
    background-color: rgba(255, 255, 255, .6);
    @include transition(all, 0.5s);
    @include media($xs) {
        height: 120px;
    }
    @include media($md) {
        height: 210px;
        padding-top: 60px;
    }
    &.active {
        height: 170px;
        @include media($xs) {
            height: 200px;
        }
        @include media($md) {
            height: 260px;
        }
        @include media($lg) {
            height: 280px;
        }
        @include media($xlg) {
            height: 290px;
        }
    }
    form {
        @include clearfix;
        height: 100%;
        div.box {
            @include span-columns(12);
            @include media($md) {
                @include span-columns(11);
                @include shift(3);
            }
            &:before {
                width: 21px;
                height: 21px;
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 36px;
                background-image: url('/assets/img/search-thick.svg');
                @include media($xs) {
                    width: 42px;
                    height: 42px;
                    top: 42px;
                }
                @include media($md) {
                    width: 84px;
                    height: 84px;
                    top: 0px;
                    background-image: url('/assets/img/search.svg');
                }
            }
            input[type=text] {
                border: 0;
                background-color: transparent;
                padding-left: 30px;
                font-size: 18px;
                font-weight: 400;
                color: #7b889b;
                margin-top: 25px;
                width: 100%;
                @include media($xs) {
                    font-size: 26px;
                    margin-top: 37px;
                    padding-left: 52px;
                }
                @include media($sm) {
                    font-size: 34px;
                    margin-top: 28px;
                    font-weight: 300;
                }
                @include media($md) {
                    font-size: 40px;
                    padding-left: 110px;
                    margin-top: 8px;
                }
                @include media($lg) {
                    font-size: 48px;
                    margin-top: 0;
                }
                @include media($xlg) {
                    font-size: 60px;
                }
            }
            input[type=submit] {
                display: none;
            }
            span.twitter-typeahead {
                width: 100%;
                pre {
                    background-color: red;
                }
                .tt-menu {
                    //margin-left: 120px;
                    font-size: 18px;
                    cursor: pointer;
                    @include media($md) {
                        margin-left: 120px;
                        font-size: 24px;
                    }
                    .tt-dataset {
                        div:nth-child(n+4) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@mixin priceBoxes() {
    .priceBoxes {
        .box {
            @include clearfix;
            margin: 15px 0;
            background: white;
            padding-bottom: 30px;
            @include media($md) {
                padding-bottom: 0;
                width: 125%;
                left: -25%;
            }
            @include media($lg) {
                left: -20%;
            }
            .title {
                background-color: $grayBlue;
                padding: 40px 30px;
                @include clearfix;
                @include media($md) {
                    @include span-columns(3);
                    height: 250px;
                    padding-top: 80px;
                }
                @include media($lg) {
                    padding-top: 40px;
                    height: 205px;
                }
                @include media($xlg) {
                    height: auto;
                }
                .price {
                    font-size: 44px;
                    color: white;
                    @include media($xs) {
                        @include span-columns(6);
                    }
                    @include media($md) {
                        width: 100%;
                        text-align: center;
                        font-size: 26px;
                    }
                    @include media($lg) {
                        font-size: 34px;
                    }
                    @include media($xlg) {
                        font-size: 44px;
                    }
                }
                .desc {
                    color: white;
                    font-size: 14px;
                    @include media($xs) {
                        @include span-columns(6);
                        line-height: 50px;
                    }
                    @include media($md) {
                        line-height: 1.6em;
                        width: 100%;
                        text-align: center;
                        padding-top: 20px;
                    }
                    @include media($xlg) {
                        font-size: 16px;
                    }
                }
            }
            .options {
                padding: 24px 30px 0 10px;
                @include media($md) {
                    @include span-columns(9);
                    @include omega(2n);
                }
                h6 {
                    color: $purple;
                    margin: 0;
                    a {
                        font-size: 14px;

                        color: $teal;

                        @include media($md) {
                            display: block;
                            margin: 10px 0;
                        }

                        @include media($lg) {
                            display: inline;
                            padding-left: 20px;
                            margin: 0;
                        }
                        i {
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }
                }
                p.excerpt {
                    font-size: 13px;
                    margin: 0;
                }
                .data {
                    .cost {

                        @include media($md) {
                            @include span-columns(7);
                        }

                        /* @include media($lg) {
                            @include span-columns(11);
                        } */
                        table {
                            width: 100%;
                            margin: 20px 0;
                            @include media($md) {
                                width: 100%;
                                margin-bottom: 0;
                                table-layout: fixed;
                            }
                            @include media($lg) {
                                //width: 300px;
                            }
                            @include media($xlg) {
                                //width: 400px;
                            }
                            tr {
                                td {
                                    padding: 2px;
                                    font-size: 14px;
                                    &:first-child {
                                        width: 65%;
                                    }
                                    &:last-child {
                                        width: 45%;
                                        font-weight: bold;
                                    }
                                    p {
                                        margin: 0;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                    .action {
                        //display: none;
                        @include media($md) {
                            @include span-columns(4.5);
                            @include shift(.5);
                        }
                        @include media($lg) {
                            //display: none;
                        }
                        .view {
                            text-align: left;
                            margin: 0;
                            text-align: center;
                            padding: 10px 0;
                            a {
                                font-size: 14px;
                            }
                        }
                        .add {
                            margin: 0;
                            a {
                                font-size: 14px;
                                padding: 10px 20px;
                            }
                        }
                    }
                }
            }
            .action {
                text-align: center;
                @include clearfix;
                @include media($md) {
                    display: none;
                }
                @include media($lg) {
                    @include span-columns(3.5);
                    padding-top: 20px;
                }
                >div {
                    @include media($xs) {
                        @include span-columns(6);
                    }
                    @include media($md) {
                        width: 100%;
                    }
                    &.view {
                        padding: 20px 0;
                        a {
                            color: $teal;
                            font-weight: bold;
                            padding: 20px 0;
                            font-size: 14px;
                            @include media($xlg) {
                                font-size: 16px;
                            }
                            i {
                                font-size: 13px;
                                font-weight: bold;
                            }
                        }
                    }
                    &.add {
                        @include clearfix;
                        padding-top: 15px;
                        @include media($md) {
                            padding-top: 0;
                        }
                        a {
                            background-color: $purple;
                            padding: 10px 25px;
                            border-radius: 25px;
                            color: white;
                            display: inline-block;
                            font-size: 14px;
                            @include media($xs) {
                                float: left;
                            }
                            @include media($md) {
                                font-size: 16px;
                                padding: 15px 15px;
                                float: none;
                            }
                            @include media($lg) {
                                font-size: 13px;
                            }
                            @include media($xlg) {
                                font-size: 16px;
                                padding: 15px 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin bgFix {
    background-position: center;
    background-size: cover;
}

@mixin aCover {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 500;
}

@mixin katyGradient {
	background: rgb(4,0,188);
	background: linear-gradient(90deg, rgba(4,0,188,1) 0%, rgba(121,11,143,1) 80%);
}

@mixin katyButtonGradient {
	background:#FF6248;
	background:linear-gradient(233deg, rgba(251, 110, 84, 1) 45%, rgba(121, 11, 143, 1) 100%);
}