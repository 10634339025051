// Set your global variables below
// i.e. $foo: #000;
// Main colour palette

/////////// Standard Colors ////////////////

$blue:#001837;
$teal:#FF6248;
$lightBlue: #97a6b9;
$grayBlue: #1986C7;
$purple: #315A5E;
$softGray : #f1f1f1;
$almostWhite: #eee;
$borderColor : #e1e1e1;
$gray: #E8E9E7;
$darkerGray: #CACCCA;
$text: #404240;
$navy: #3B5156;
$yellow:#FF6248;
$red: #F04E53;
$haze: #F1F0DF;
$lightYellow: #FFF8DA;
$purple: #1F009F;